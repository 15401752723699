import { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardActions,
  Button,
  LinearProgress,
  Typography,
  Drawer,
} from "@mui/material";
import StorageCardItem from "../StorageCardItem/StorageCardItem";
import DeleteIcon from "@mui/icons-material/Delete";
import StorageIllustration from "./36118.jpg";
import EmptyBoxesIllustration from "./empty-boxes.png";
import { Link } from "react-router-dom";
import moment from "moment";
import Grid from "@mui/material/Grid2";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          sx={{ color: "text.secondary" }}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

function LinearWithValueLabel({ storage }) {
  const ratio = (storage.usedCapacity || 0) / 90;
  return (
    <Box display="flex">
      <Typography
        sx={{ color: "text.secondary", fontSize: 12, marginRight: "0.75em" }}
      >
        CAPACITY:
      </Typography>
      <Box width="200px">
        <LinearProgressWithLabel value={ratio} />
      </Box>
    </Box>
  );
}

export default function StorageCard({
  storage,
  currentItem,
  onDelete,
  onEmpty,
  onSelectCurrentItem,
}) {
  const [openDrawer, setOpenDrawer] = useState(false);
  const handleItemClick = (selectedItem) => {
    onSelectCurrentItem(selectedItem);
  };

  const handleDelete = () => {
    onDelete(storage);
  };

  const handleEmptyStorage = () => {
    onEmpty(storage);
  };

  return (
    <Box mb="3.5em" width="100%">
      <Card variant="outlined">
        <Box
          p="0.75em"
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          borderBottom="1px solid #ddd"
          justifyContent="space-between"
        >
          <Box>
            <Typography
              sx={{
                color: "text.primary",
                fontSize: 20,
                marginBottom: { xs: "1em", md: "0" },
              }}
            >
              {storage.term === "MONTHLY" ? "Monthly" : "Annual"} Storage{" "}
              {storage.size === "SMALL"
                ? "(3 x 3)"
                : storage.size === "MEDIUM"
                ? "(6 x 6)"
                : "(10 x 10)"}
            </Typography>

            {!storage.emptyRequested ? (
              <Typography sx={{ color: "text.secondary", fontSize: 12 }}>
                {storage.createdAt ? (
                  <span>
                    CREATED AT:{" "}
                    {moment
                      .unix(storage.createdAt / 1000)
                      .format("MMMM Do YYYY, h:mm:ss a")}
                  </span>
                ) : (
                  "Unknown Creation Time"
                )}
              </Typography>
            ) : (
              <Typography
                sx={{
                  color: "text.secondary",
                  textTransform: "uppercase",
                  fontSize: 12,
                }}
              >
                This storage is being emptied.
              </Typography>
            )}
          </Box>
          {storage.status === "ACTIVE" && (
            <LinearWithValueLabel storage={storage} />
          )}
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          p="0.75em"
          background="#eee"
        >
          <Box width="100%" display="flex" p="0.75em">
            {!!(storage.items || []).length && !storage.deleteRequested && (
              <Grid container spacing={2} width="100%">
                {(storage.items || []).map((item) => (
                  <Grid
                    size={{ sx: 12, sm: 6, md: 2 }}
                    key={item._id}
                    width="100%"
                  >
                    <StorageCardItem
                      disabled={storage.emptyRequested}
                      selected={currentItem?._id === item._id}
                      item={item}
                      onClick={handleItemClick}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
            {storage.status === "ACTIVE" && !storage.items?.length && (
              <Box
                textAlign="center"
                display="flex"
                width="100%"
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
              >
                <Box width="300px">
                  <img
                    src={EmptyBoxesIllustration}
                    style={{ width: "300px", height: "200px" }}
                  />
                  <Typography>
                    Your storage container is empty. Schedule a move by
                    selecting
                    <br />
                    "Add items to storage"
                  </Typography>
                </Box>
              </Box>
            )}

            {storage.status === "INACTIVE" && !!storage.deleteRequested && (
              <Box
                textAlign="center"
                display="flex"
                width="100%"
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
              >
                <Box width="300px">
                  <img
                    src={EmptyBoxesIllustration}
                    style={{
                      width: "300px",
                      height: "200px",
                      filter: "grayscale(1)",
                    }}
                  />
                  <Typography>
                    We are removing your storage. Please call support to cancel
                  </Typography>
                </Box>
              </Box>
            )}
            {storage.status === "CREATING" ||
              (storage.status === "REQUESTED" && (
                <Box
                  textAlign="center"
                  display="flex"
                  width="100%"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <Box>
                    <img
                      src={StorageIllustration}
                      style={{ width: "300px", height: "200px" }}
                    />
                    <div>
                      <Typography>
                        {storage.status === "REQUESTED"
                          ? "Your storage container is being prepared."
                          : "We're creating your physical storage space."}
                      </Typography>
                      <Typography
                        sx={{ color: "text.secondary", fontSize: 12 }}
                      >
                        ESTIMATED TIME: 11 HOURS
                      </Typography>
                    </div>
                  </Box>
                </Box>
              ))}
          </Box>
        </Box>
        <CardActions sx={{ padding: 0 }}>
          <Box borderTop="1px solid #eee" width="100%" p="0.75em">
            {storage.status !== "INACTIVE" && (
              <Link to={`/request-storage/${storage._id}`}>
                <Button size="small" variant="outlined">
                  Add items to Storage
                </Button>
              </Link>
            )}

            {storage.status === "ACTIVE" &&
              !!storage?.items?.length &&
              !storage.emptyRequested && (
                <Button
                  size="small"
                  variant="outlined"
                  onClick={handleEmptyStorage}
                  sx={{ marginLeft: "0.5em" }}
                >
                  Empty Storage
                </Button>
              )}
            {storage.status !== "CREATED" && storage.status !== "INACTIVE" && (
              <Button
                size="small"
                variant="text"
                sx={{ marginLeft: "0.5em" }}
                startIcon={<DeleteIcon />}
                onClick={handleDelete}
              >
                {storage.status === "REQUESTED"
                  ? "Request Cancellation"
                  : "Remove Storage"}
              </Button>
            )}
          </Box>
        </CardActions>
      </Card>
    </Box>
  );
}
