import {
  Box,
  Button,
  Typography,
  SwipeableDrawer,
  Skeleton,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import * as React from "react";

function MobileDrawer({ content, onCancelItem, currentItem }) {
  return (
    <SwipeableDrawer
      anchor="left"
      open={!!currentItem}
      onClose={onCancelItem}
      onOpen={() => {}}
      sx={{ display: { xs: "initial", md: "none" } }}
    >
      <Box p="2em" width="100%" minWidth="300px" boxSizing="border-box">
        {content}
      </Box>
    </SwipeableDrawer>
  );
}

function DesktopDrawer({ content, onCancelItem, currentItem }) {
  return (
    <Box
      sx={{ display: { xs: "none", md: "initial" } }}
      position="fixed"
      top="2.875em"
      bottom={0}
      left={currentItem ? "0" : "-110%"}
      style={{ transition: "left 0.7s" }}
      width="200px"
      backgroundColor="white"
      zIndex={99}
      borderRight="1px solid #eee"
      boxShadow="3px 2px 4px rgba(155,155,155,0.2);"
      padding="1em"
    >
      <Box
        display="flex"
        flexDirection="row-reverse"
        mb="1em"
        onClick={onCancelItem}
      >
        <ClearIcon fill="#555" sx={{ cursor: "pointer" }} />
      </Box>
      <Box
        width="200px"
        display={{ xs: "none", md: "flex" }}
        flexDirection="column"
      >
        {content}
      </Box>
    </Box>
  );
}

export default function CurrentStorageDrawer({
  currentItem,
  onCancelItem = () => {},
}) {
  const content = (
    <>
      <Box border="1px solid #eee;" textAlign="center">
        {currentItem ? (
          <img src={currentItem?.photo?.url} style={{ height: "100px" }} />
        ) : (
          <Skeleton variant="rounded" width="100%" height={100} />
        )}
      </Box>
      <Typography mt="0.5em">
        {currentItem ? (
          currentItem?.name
        ) : (
          <Skeleton variant="rounded" width="100%" height={24} />
        )}
      </Typography>

      <Box mt="2em"></Box>

      <Box width="100%">
        <Button sx={{ width: "100%" }} variant="contained">
          Request item
        </Button>
      </Box>

      <Box width="100%">
        <Button sx={{ width: "100%" }}>Donate item</Button>
      </Box>
    </>
  );

  return (
    <>
      <DesktopDrawer
        currentItem={currentItem}
        onCancelItem={onCancelItem}
        content={content}
      />
      <MobileDrawer
        currentItem={currentItem}
        onCancelItem={onCancelItem}
        content={content}
      />
    </>
  );
}
