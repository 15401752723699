import { useEffect, useState } from "react";
import StorageCard from "../../static/StorageCard/StorageCard";
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Snackbar,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ConfirmationModal from "../../modals/ConfirmationModal/ConfirmationModal";
import CreateStorageModal from "../../modals/CreateStorageModal/CreateStorageModal";
import { StorageController } from "../../../service/SynclisService";
import EmptyBoxes from "./empty-boxes.png";
import FixedWidthContainer from "../../reuseable/FixedWidthContainer/FixedWidthContainer";
import CurrentStorageDrawer from "../../static/CurrentStorageDrawer/CurrentStorageDrawer";

export default function StorageListingPage() {
  const [storages, setStorages] = useState(null);
  const [currentStorageToDelete, setCurrentStorageToDelete] = useState(null);
  const [currentStorageToEmpty, setCurrentStorageToEmpty] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);
  const [showToast, setShowToast] = useState();
  const [isCreateStorageModalOpen, setIsCreateStorageModalOpen] =
    useState(false);

  useEffect(() => {
    const queryStorage = async () => {
      const storage = await StorageController.get().catch((e) => {});
      setStorages(storage);
    };

    queryStorage();
  }, []);

  const handleDelete = (storage) => {
    setCurrentStorageToDelete(storage);
  };
  const handleEmpty = (storage) => {
    setCurrentStorageToEmpty(storage);
  };

  const handleDeleteRemoveStorage = async () => {
    setCurrentStorageToDelete(null);
    await StorageController.update({
      ...currentStorageToDelete,
      status: "INACTIVE",
      deleteRequested: {
        status: "REQUESTED",
        requestedTime: Date.now(),
      },
    }).catch((e) => {});

    setShowToast(true);
  };

  const handleEmptyStorage = async () => {
    await StorageController.update({
      ...currentStorageToEmpty,
      emptyRequested: {
        status: "REQUESTED",
        requestedTime: Date.now(),
      },
    }).catch((e) => {});

    setShowToast(true);
  };

  const handleCancelDelete = async (storage) => {
    await StorageController.update({
      ...storage,
      status: "ACTIVE",
      deleteRequested: null,
    }).catch((e) => {});

    setShowToast(true);
  };

  const handleToggleCreateStorageModal = () => {
    setIsCreateStorageModalOpen(!isCreateStorageModalOpen);
  };

  const handleSelectCurrentItem = (item) => {
    if (item._id === currentItem?._id) {
      setCurrentItem(null);
      return;
    }
    setCurrentItem(item);
  };

  const handleCloseDrawer = () => {
    setCurrentItem(null);
  };

  if (!storages) {
    return (
      <Box
        position="absolute"
        top="50%"
        left="50%"
        textAlign="center"
        style={{ transform: "translate(-50%, -50%)" }}
      >
        <CircularProgress size="30px" />
      </Box>
    );
  }

  if (!storages?.length) {
    return (
      <Box
        position="absolute"
        top="50%"
        left="50%"
        textAlign="center"
        style={{ transform: "translate(-50%, -50%)" }}
      >
        <img src={EmptyBoxes} style={{ width: "300px" }} />
        <Typography gutterBottom sx={{ color: "text.primary", fontSize: 24 }}>
          It's a bit empty here.
        </Typography>
        <Typography>You currently don't have any storage available</Typography>

        <Button
          onClick={handleToggleCreateStorageModal}
          size="small"
          variant="contained"
          sx={{ marginTop: "1.5em" }}
        >
          Create Storage
        </Button>
        <CreateStorageModal
          onClose={handleToggleCreateStorageModal}
          isOpen={isCreateStorageModalOpen}
        />
      </Box>
    );
  }

  return (
    <>
      <Snackbar
        open={showToast}
        onClose={() => setShowToast(false)}
        message="Requested to delete storage"
        key="a"
        autoHideDuration={10000}
      />
      <Box mb="2em" mt="2em">
        <CurrentStorageDrawer
          currentItem={currentItem}
          onCancelItem={handleCloseDrawer}
        />
        <FixedWidthContainer>
          {(storages || []).map((storage) => (
            <StorageCard
              key={storage._id}
              storage={storage}
              currentItem={currentItem}
              onSelectCurrentItem={handleSelectCurrentItem}
              onDelete={handleDelete}
              onEmpty={handleEmpty}
            />
          ))}
          <Box px="1em">
            <Button
              onClick={handleToggleCreateStorageModal}
              size="small"
              variant="contained"
              sx={{ marginLeft: "0.5em" }}
            >
              <AddIcon /> Add New Storage
            </Button>
          </Box>

          <ConfirmationModal
            title="Removing Storage"
            subtitle={`You are attempting to remove your ${
              currentStorageToDelete?.size
            } storage. It currently has ${
              currentStorageToDelete?.items?.length ?? "no"
            } items. ${
              currentStorageToDelete?.items?.length
                ? "They will be shipped back to you. Please ensure that your address is current."
                : ""
            } Once your storage is physically removed it is gone forever.`}
            isOpen={!!currentStorageToDelete}
            confirmText="I&nbsp;Understand"
            onConfirm={handleDeleteRemoveStorage}
            onClose={() => setCurrentStorageToDelete(null)}
          />

          <ConfirmationModal
            title="Empty Storage"
            subtitle={`You are attempting to empty your ${
              currentStorageToEmpty?.size
            } storage. It currently has ${
              currentStorageToEmpty?.items?.length ?? "no"
            } items. ${
              currentStorageToEmpty?.items?.length
                ? "They will be shipped back to you. Please ensure that your address is current."
                : ""
            } You will keep your storage, and can continue to use it.`}
            isOpen={!!currentStorageToEmpty}
            confirmText="I&nbsp;Understand"
            onConfirm={handleEmptyStorage}
            onClose={() => setCurrentStorageToEmpty(null)}
          />

          <CreateStorageModal
            onClose={handleToggleCreateStorageModal}
            isOpen={isCreateStorageModalOpen}
          />
        </FixedWidthContainer>
      </Box>
    </>
  );
}
