import * as React from "react";
import { Box } from "@mui/material";

export default function ScrollingContainer({ children }) {
  return (
    <Box width="100%" height="100%" sx={{ overflowY: "scroll" }}>
      {children}
    </Box>
  );
}
