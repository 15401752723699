import { useEffect, useState } from "react";
import { Card, Box, TextField, Button, Tooltip } from "@mui/material";
import SynclisLogo from "../../../../assets/images/logo.svg";
import { AuthController } from "../../../../service/SynclisService";
import LocalStorageUtility from "../../../../utilities/LocalStorageUtility";
import { useNavigate } from "react-router-dom";
import HelpIcon from "@mui/icons-material/Help";
import BackButton from "../../../reuseable/BackButton/BackButton";
import { AbsoluteCenterNoResponsive } from "../../../reuseable/AbsoluteCenter/AbsoluteCenter";
import { useSearchParams } from "react-router-dom";

export default function LoginPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (LocalStorageUtility.get("synclis_session")) {
      navigate("/inventory");
    }
  }, [navigate]);

  useEffect(() => {
    const sessionToken = searchParams.get("sessionToken");
    if (sessionToken) {
      const a = async () => {
        await AuthController.authThroughSession()
          .then(() => {
            navigate("/inventory");
          })
          .catch((e) => {});
      };

      LocalStorageUtility.create("synclis_session", sessionToken);
      a();
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");
    if (
      !email ||
      !/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
        email
      )
    ) {
      return setError("Email is empty or is an invalid email");
    }
    if (!password) {
      return setError("Password is empty");
    }
    const user = await AuthController.signin({
      email,
      password,
    }).catch((e) => {
      setError("Incorrect credentials");
    });

    if (user) {
      navigate("/inventory");
    }
  };

  if (LocalStorageUtility.get("synclis_session")) {
    return null;
  }

  return (
    <>
      <Box p="1em">
        <BackButton onClick={() => navigate("/")} />
      </Box>
      <AbsoluteCenterNoResponsive>
        <Card variant="elevation" sx={{ width: 350 }}>
          <Box
            p="1em"
            display="flex"
            flexDirection="column"
            justifyItems="center"
            alignItems="center"
          >
            <img src={SynclisLogo} style={{ width: "200px" }} />
            <Box
              backgroundColor="#fcc"
              color="#f44"
              fontSize="12px"
              p="1em"
              width="100%"
              boxSizing="border-box"
              borderRadius="3px"
              marginTop="1em"
              sx={{ opacity: error ? 1 : 0, transition: "opacity 0.3s" }}
            >
              {error}
            </Box>
            <form onSubmit={handleLogin} style={{ width: "100%" }}>
              <TextField
                label="Email"
                variant="outlined"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                sx={{ marginBottom: "0.75em", marginTop: "1em", width: "100%" }}
              />
              <Box width="100%" position="relative">
                <TextField
                  label="Password"
                  variant="outlined"
                  type="password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  sx={{ marginBottom: "0.75em", width: "100%" }}
                />
                <Box
                  onClick={() => navigate("/forgot-password")}
                  position="absolute"
                  zIndex={2}
                  top="1em"
                  right="1em"
                  sx={{ cursor: "pointer", color: "text.secondary" }}
                >
                  <Tooltip title="Forgot Password?" placement="top">
                    <HelpIcon />
                  </Tooltip>
                </Box>
              </Box>
              <Button variant="contained" type="submit" sx={{ width: "100%" }}>
                Login
              </Button>
            </form>
          </Box>
        </Card>
        <Box
          mt="2em"
          display="flex"
          flexDirection="column"
          gap="0.5em"
          justifyContent="center"
        >
          <Button
            variant="outlined"
            onClick={() => navigate("/create-account")}
          >
            Sign up with email
          </Button>

          <Button
            variant="outlined"
            onClick={() =>
              (window.location = "https://api.synclis.com/auth/google")
            }
          >
            Sign up with Google
          </Button>

          <Button
            variant="outlined"
            onClick={() =>
              (window.location = "https://api.synclis.com/auth/apple")
            }
          >
            Sign up with Apple
          </Button>
        </Box>
      </AbsoluteCenterNoResponsive>
    </>
  );
}
