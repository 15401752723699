import * as React from "react";
import { Card, Modal, Typography, Box, Button } from "@mui/material";
import AbsoluteCenter from "../../reuseable/AbsoluteCenter/AbsoluteCenter";

export default function ConfirmationModal({
  isOpen,
  onClose,
  onConfirm,
  title = "",
  subtitle = "",
  cancelText = "Cancel",
  confirmText = "OK",
}) {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box position="relative" height="100%">
        <AbsoluteCenter>
          <Card>
            <Box p="2em">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {title}
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {subtitle}
              </Typography>
            </Box>
            <Box
              p="2em"
              pt="0"
              display="flex"
              alignItems="right"
              justifyContent="right"
            >
              <Button variant="outlined" onClick={onClose}>
                {cancelText}
              </Button>
              <Box ml="1em">
                <Button
                  variant="contained"
                  onClick={onConfirm}
                  sx={{ minWidth: "100px" }}
                >
                  {confirmText}
                </Button>
              </Box>
            </Box>
          </Card>
        </AbsoluteCenter>
      </Box>
    </Modal>
  );
}
