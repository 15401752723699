import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Typography } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

export default function StorageCard({
  item,
  onClick,
  selected,
  disabled = false,
}) {
  return (
    <Box sx={{ width: "100%" }}>
      <Card
        raised={selected}
        onClick={() => !disabled && onClick(item)}
        sx={{ width: "100%", cursor: "pointer", position: "relative" }}
      >
        {disabled ? (
          <Box position="absolute" color={"green"} zIndex="1">
            <AccessTimeIcon />
          </Box>
        ) : (
          ""
        )}
        <Box
          textAlign="center"
          borderBottom="1px solid #eee"
          sx={{ filter: disabled ? "grayscale(1)" : "" }}
        >
          <img src={item.photo.url} style={{ height: "100px" }} />
        </Box>
        <CardContent>
          <Typography sx={{ color: "text.primary", fontSize: 12 }}>
            {item.name}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
}
