import { useState, useEffect, forwardRef } from "react";
import { Box, Table, Skeleton } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableVirtuoso } from "react-virtuoso";
import { StorageController } from "../../../../service/SynclisService";
import moment from "moment";
import StorageRequestDropdown from "./StorageRequestDropdown";
import ConfirmationModal from "../../../modals/ConfirmationModal/ConfirmationModal";

const columns = [
  {
    width: 200,
    label: "User",
    dataKey: "owner",
  },
  {
    width: 120,
    label: "Status",
    dataKey: "status",
  },
  {
    width: 120,
    label: "Size",
    dataKey: "size",
  },
  {
    width: 120,
    label: "Term",
    dataKey: "term",
  },
  {
    width: 200,
    label: "Created At",
    dataKey: "createdAt",
  },
  {
    width: 50,
    label: "Actions",
    dataKey: "actions",
  },
];

const VirtuosoTableComponents = {
  Scroller: forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table
      {...props}
      sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
    />
  ),
  TableHead: forwardRef((props, ref) => <TableHead {...props} ref={ref} />),
  TableRow,
  TableBody: forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
};

function fixedHeaderContent() {
  return (
    <TableRow>
      {columns.map((column) => (
        <TableCell
          key={column.dataKey}
          variant="head"
          align={column.numeric || false ? "right" : "left"}
          style={{ width: column.width }}
          sx={{ backgroundColor: "#eee" }}
        >
          {column.label}
        </TableCell>
      ))}
    </TableRow>
  );
}

export default function StorageRequestsPage() {
  const [storages, setStorages] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [currentStorage, setCurrentStorage] = useState(null);

  useEffect(() => {
    const a = async () => {
      const storages = await StorageController.get();
      setStorages(storages);
    };
    a();
  }, [openDeleteModal]);

  const toggleDeleteModal = (storage) => {
    setOpenDeleteModal(!openDeleteModal);
    setCurrentStorage(storage);
  };

  const handleModifyStatus = async (storage) => {
    await StorageController.update({
      ...storage,
      status: "ACTIVE",
      deleteRequested: null,
      createRequested: null,
      emptyRequested: null,
    });
  };

  const handleEmptyStorage = async (storage) => {
    await StorageController.update({
      ...storage,
      status: "ACTIVE",
      items: [],
      emptyRequested: null,
    });

    // Send items to delivery
  };

  const handleDeleteStorage = async () => {
    await StorageController.remove(currentStorage);
    toggleDeleteModal();
  };

  const rowContent = (_index, row) => {
    return (
      <>
        {columns.map((column) => {
          const genContent = () => {
            if (column.dataKey === "createdAt") {
              return moment
                .unix(row[column.dataKey] / 1000)
                .format("MMMM Do YYYY, h:mm:ss a");
            } else if (column.dataKey === "owner") {
              const owner = row[column.dataKey];
              return owner.email;
            } else if (column.dataKey === "actions") {
              return (
                <StorageRequestDropdown
                  storage={row}
                  onDelete={() => toggleDeleteModal(row)}
                  onModifyStatus={() => handleModifyStatus(row)}
                  onEmptyStorage={() => handleEmptyStorage(row)}
                />
              );
            } else if (column.dataKey === "status") {
              if (row.emptyRequested) return "EMPTY STORAGE";

              if (row.deleteRequested) return "DELETE STORAGE";
              return row[column.dataKey];
            }

            return row[column.dataKey];
          };

          return (
            <TableCell
              key={column.dataKey}
              align={column.numeric || false ? "right" : "left"}
            >
              {genContent()}
            </TableCell>
          );
        })}
      </>
    );
  };

  return (
    <>
      <Paper style={{ height: 400, width: "100%" }}>
        {storages === null && (
          <Box p="1em">
            <Skeleton />
            <Skeleton animation="wave" />
            <Skeleton />
          </Box>
        )}
        <TableVirtuoso
          data={storages}
          components={VirtuosoTableComponents}
          fixedHeaderContent={fixedHeaderContent}
          itemContent={rowContent}
        />
      </Paper>
      <ConfirmationModal
        title="Are you sure you want to delete this storage?"
        subtitle="This should only be deleted if the storage is COMPLETELY physically removed from the facility."
        onClose={toggleDeleteModal}
        onConfirm={handleDeleteStorage}
        isOpen={openDeleteModal}
      />
    </>
  );
}
