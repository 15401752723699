import { useState, useEffect, forwardRef } from "react";
import { Box, Table, Skeleton } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableVirtuoso } from "react-virtuoso";
import {
  UserController,
  RoleController,
} from "../../../../service/SynclisService";
import moment from "moment";
import UserDropdown from "./UserDropdown";
import UpdateUserModal from "../../../modals/UpdateUserModal/UpdateUserModal";
import ConfirmationModal from "../../../modals/ConfirmationModal/ConfirmationModal";

const columns = [
  {
    width: 120,
    label: "ID",
    dataKey: "_id",
  },
  {
    width: 200,
    label: "User",
    dataKey: "name",
  },
  {
    width: 120,
    label: "Email",
    dataKey: "email",
  },
  {
    width: 120,
    label: "Phone",
    dataKey: "phone",
  },
  {
    width: 200,
    label: "Joined at",
    dataKey: "createdAt",
  },
  {
    width: 50,
    label: "Actions",
    dataKey: "actions",
  },
];

const VirtuosoTableComponents = {
  Scroller: forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table
      {...props}
      sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
    />
  ),
  TableHead: forwardRef((props, ref) => <TableHead {...props} ref={ref} />),
  TableRow,
  TableBody: forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
};

function fixedHeaderContent() {
  return (
    <TableRow>
      {columns.map((column) => (
        <TableCell
          key={column.dataKey}
          variant="head"
          align={column.numeric || false ? "right" : "left"}
          style={{ width: column.width }}
          sx={{ backgroundColor: "#eee" }}
        >
          {column.label}
        </TableCell>
      ))}
    </TableRow>
  );
}

export default function UsersPage() {
  const [users, setUsers] = useState(null);
  const [roles, setRoles] = useState(null);
  const [isRoleModalOpen, setIsRoleModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const a = async () => {
      const users = await UserController.get();
      const roles = await RoleController.get();
      setUsers(users);
      setRoles(roles);
    };
    a();
  }, []);

  const handleSelectRole = (user) => {
    setIsRoleModalOpen(true);
    setCurrentUser(user);
  };

  const toggleCloseRoleModal = () => {
    setIsRoleModalOpen(!isRoleModalOpen);
    setCurrentUser(null);
  };

  const handleDeleteUser = async (row) => {
    console.log(isDeleteModalOpen);
    await UserController.remove(isDeleteModalOpen._id);
    setIsDeleteModalOpen(null);
  };

  function rowContent(_index, row) {
    return (
      <>
        {columns.map((column) => {
          const genContent = () => {
            if (column.dataKey === "createdAt") {
              return moment
                .unix(row[column.dataKey] / 1000)
                .format("MMMM Do YYYY, h:mm:ss a");
            } else if (column.dataKey === "actions") {
              return (
                <UserDropdown
                  user={row}
                  onDelete={() => setIsDeleteModalOpen(row)}
                  onSelectRole={() => handleSelectRole(row)}
                />
              );
            }
            return row[column.dataKey];
          };

          return (
            <TableCell
              key={column.dataKey}
              align={column.numeric || false ? "right" : "left"}
            >
              {genContent()}
            </TableCell>
          );
        })}
      </>
    );
  }

  return (
    <>
      <Paper style={{ height: 400, width: "100%" }}>
        {users === null && (
          <Box p="1em">
            <Skeleton />
            <Skeleton animation="wave" />
            <Skeleton />
          </Box>
        )}
        <TableVirtuoso
          data={users}
          components={VirtuosoTableComponents}
          fixedHeaderContent={fixedHeaderContent}
          itemContent={rowContent}
        />
      </Paper>
      <UpdateUserModal
        isOpen={isRoleModalOpen}
        onUpdate={toggleCloseRoleModal}
        onClose={toggleCloseRoleModal}
        currentUser={currentUser}
        roles={roles}
        user
      />
      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDeleteUser}
        title="Are you sure you want to delete this user?"
        subtitle="This action is irreversible, please be certain that you want to remove this user."
      />
    </>
  );
}
