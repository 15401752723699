import { useEffect, useState } from "react";

export default function Payment({ amount, onSuccessfulPayment, onError }) {
  const [loaded, setLoaded] = useState(100);

  useEffect(() => {
    if (!amount) return;

    if (window.Stripe) {
      const stripe = window.Stripe("pk_test_9vPe6QR4kL7AF30tveOjBha7");
      const appearance = {
        /* appearance */
      };
      const options = {
        buttonType: {
          applePay: "buy",
          googlePay: "buy",
          paypal: "buynow",
        },
      };
      const elements = stripe.elements({
        mode: "payment",
        amount,
        currency: "usd",
        appearance,
      });
      const expressCheckoutElement = elements.create(
        "expressCheckout",
        options
      );

      expressCheckoutElement.on("confirm", async (event) => {
        const paymentIntent = await fetch(
          "https://api.synclis.com/authorizeStripePayment"
        )
          .then((res) => res.json()) // Parse the response as JSON.
          .catch((err) => {
            console.error("Error fetching merchant session", err);
          });

        await onSuccessfulPayment();

        stripe
          .confirmPayment({
            elements,
            clientSecret: paymentIntent.client_secret,
            confirmParams: {
              return_url: window.location.href,
            },
          })
          .then(function (result) {
            if (result.error) {
              // Inform the customer that there's an error.
              onError();
            }
          });
      });

      if (loaded !== amount)
        expressCheckoutElement.mount("#express-checkout-element");

      setLoaded(amount);
    }
  }, [amount]);
  return <div id="express-checkout-element"></div>;
}
