import { Box } from "@mui/material";
import Header from "../AdminHeader/AdminHeader";
import React from "react";

export default function PageWrapper({ children }) {
  return (
    <>
      <Header />
      <Box mt="3em">
        <Box p="2em">{children}</Box>
      </Box>
    </>
  );
}
