import { useState } from "react";
import {
  Card,
  Box,
  TextField,
  Button,
  Typography,
  Tooltip,
} from "@mui/material";
import SynclisLogo from "../../../../assets/images/logo.svg";
import { UserController } from "../../../../service/SynclisService";

import BackButton from "../../../reuseable/BackButton/BackButton";
import { useNavigate } from "react-router-dom";
import HelpIcon from "@mui/icons-material/Help";
import { AbsoluteCenterNoResponsive } from "../../../reuseable/AbsoluteCenter/AbsoluteCenter";

export default function CreateAccountPage() {
  const [error, setError] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");

  const [viewPassword, setViewPassword] = useState(false);
  const [viewPasswordAgain, setViewPasswordAgain] = useState(false);
  const navigate = useNavigate();

  const handleSignup = async (e) => {
    e.preventDefault();
    setError("");

    if (!name) {
      return setError("No name is set");
    }

    if (
      !email ||
      !/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
        email
      )
    ) {
      return setError("Incorrect email set");
    }

    if (password !== passwordAgain) {
      return setError("Passwords do not match");
    }

    const user = await UserController.create({
      name,
      email,
      password,
    }).catch((e) => {
      console.log(e);
      setError("Unable to create account");
    });

    if (user) {
      navigate("/inventory");
    }
  };

  return (
    <>
      <Box p="1em">
        <BackButton onClick={() => navigate("/login")} />
      </Box>
      <AbsoluteCenterNoResponsive>
        <Card variant="elevation" sx={{ width: 350 }}>
          <Box
            p="1em"
            display="flex"
            flexDirection="column"
            justifyItems="center"
            alignItems="center"
          >
            <img src={SynclisLogo} style={{ width: "200px" }} />
            <Typography
              gutterBottom
              sx={{
                mt: 2,
                color: "text.secondary",
                fontSize: "14px",
                textAlign: "center",
              }}
            >
              Enter some basic information
              <br />
              to create your account
            </Typography>
            <Box
              backgroundColor="#fcc"
              color="#f44"
              fontSize="12px"
              p="1em"
              width="100%"
              boxSizing="border-box"
              borderRadius="3px"
              marginTop="1em"
              textAlign="center"
              marginBottom="2em"
              height={error ? "default" : 0}
              sx={{
                opacity: error ? 1 : 0,
                transition: "opacity 0.3s, height 0.3s",
              }}
            >
              {error}
            </Box>
            <form onSubmit={handleSignup} style={{ width: "100%" }}>
              <TextField
                label="Name"
                variant="outlined"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                sx={{
                  marginBottom: "0.75em",
                  marginTop: "0.25em",
                  width: "100%",
                }}
              />
              <TextField
                label="Email"
                variant="outlined"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                sx={{
                  marginBottom: "0.75em",
                  marginTop: "0.25em",
                  width: "100%",
                }}
              />
              <Box width="100%" position="relative">
                <TextField
                  label="Password"
                  variant="outlined"
                  type={!viewPassword ? "password" : "text"}
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  sx={{ marginBottom: "0.75em", width: "100%" }}
                />
                <Box
                  onClick={() => setViewPassword(!viewPassword)}
                  position="absolute"
                  zIndex={2}
                  top="1em"
                  right="1em"
                  sx={{ cursor: "pointer", color: "text.secondary" }}
                >
                  <Tooltip
                    title={!viewPassword ? "View Password" : "Hide Password"}
                    placement="top"
                  >
                    <HelpIcon />
                  </Tooltip>
                </Box>
              </Box>
              <Box width="100%" position="relative">
                <TextField
                  label="Password Again"
                  variant="outlined"
                  type={!viewPasswordAgain ? "password" : "text"}
                  value={passwordAgain}
                  onChange={(e) => {
                    setPasswordAgain(e.target.value);
                  }}
                  sx={{ marginBottom: "0.75em", width: "100%" }}
                />
                <Box
                  onClick={() => setViewPasswordAgain(!viewPasswordAgain)}
                  position="absolute"
                  zIndex={2}
                  top="1em"
                  right="1em"
                  sx={{ cursor: "pointer", color: "text.secondary" }}
                >
                  <Tooltip
                    title={
                      !viewPasswordAgain ? "View Password" : "Hide Password"
                    }
                    placement="top"
                  >
                    <HelpIcon />
                  </Tooltip>
                </Box>
              </Box>

              <Button variant="contained" type="submit" sx={{ width: "100%" }}>
                Create Account
              </Button>
            </form>
          </Box>
        </Card>

        <Box mt="2em" display="flex" justifyContent="center">
          <Button variant="outlined" onClick={() => navigate("/login")}>
            Login
          </Button>
        </Box>
      </AbsoluteCenterNoResponsive>
    </>
  );
}
