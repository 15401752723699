import { useState, useEffect, forwardRef } from "react";
import {
  Card,
  Modal,
  Typography,
  Box,
  Button,
  Skeleton,
  Table,
} from "@mui/material";
import { FacilityController } from "../../../service/SynclisService";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableVirtuoso } from "react-virtuoso";
import moment from "moment";
import _ from "lodash";
import AbsoluteCenter from "../../reuseable/AbsoluteCenter/AbsoluteCenter";

const columns = [
  {
    width: 200,
    label: "Address",
    dataKey: "address",
  },
  {
    width: 120,
    label: "Size",
    dataKey: "size",
  },
  {
    width: 120,
    label: "Status",
    dataKey: "status",
  },
  {
    width: 120,
    label: "Phone",
    dataKey: "phone",
  },
  {
    width: 200,
    label: "Created at",
    dataKey: "createdAt",
  },
];

const VirtuosoTableComponents = {
  Scroller: forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table
      {...props}
      sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
    />
  ),
  TableHead: forwardRef((props, ref) => <TableHead {...props} ref={ref} />),
  TableRow,
  TableBody: forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
};

function fixedHeaderContent() {
  return (
    <TableRow>
      {columns.map((column) => (
        <TableCell
          key={column.dataKey}
          variant="head"
          align={column.numeric || false ? "right" : "left"}
          style={{ width: column.width }}
          sx={{ backgroundColor: "#eee" }}
        >
          {column.label}
        </TableCell>
      ))}
    </TableRow>
  );
}

export default function SelectFacilityModal({
  isOpen,
  onClose,
  onSelect = _.noop,
}) {
  const [facilities, setFacilities] = useState(null);
  const [currentFacility, setCurrentFacility] = useState(null);
  useEffect(() => {
    const a = async () => {
      const moves = await FacilityController.get();
      setFacilities(moves);
    };
    a();
  }, []);

  const handleSelectFacility = () => {
    onSelect(currentFacility);
    onClose();
  };

  const handleSelect = (facility) => {
    setCurrentFacility(facility);
  };

  const rowContent = (_index, row) => {
    return (
      <>
        {columns.map((column) => {
          const genContent = () => {
            if (column.dataKey === "createdAt") {
              return moment
                .unix(row[column.dataKey] / 1000)
                .format("MMMM Do YYYY, h:mm:ss a");
            } else if (column.dataKey === "address") {
              const { addressLine, city, state, zipcode } = row[column.dataKey];
              return `${addressLine} ${city}, ${state}, ${zipcode}`;
            }

            return row[column.dataKey];
          };

          return (
            <TableCell
              key={column.dataKey}
              onClick={() => handleSelect(row)}
              align={column.numeric || false ? "right" : "left"}
              sx={{
                cursor: "pointer",
                background: currentFacility?._id === row._id ? "#eee" : "",
              }}
            >
              {genContent()}
            </TableCell>
          );
        })}
      </>
    );
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box position="relative" height="100%">
        <AbsoluteCenter>
          <Card>
            <Box p="2em">
              <Typography variant="h6" component="h2">
                Select a Facility
              </Typography>
              <Typography gutterBottom sx={{ mt: 2, color: "text.secondary" }}>
                Please select the closest facility. The movers will deliver to
                the facility you assign here.
              </Typography>

              <Paper style={{ height: 400, width: "100%" }}>
                {facilities === null && (
                  <Box p="1em">
                    <Skeleton />
                    <Skeleton animation="wave" />
                    <Skeleton />
                  </Box>
                )}
                <TableVirtuoso
                  data={facilities}
                  components={VirtuosoTableComponents}
                  fixedHeaderContent={fixedHeaderContent}
                  itemContent={rowContent}
                />
              </Paper>
            </Box>
            <Box
              p="2em"
              pt="0"
              display="flex"
              alignItems="right"
              justifyContent="right"
            >
              <Button variant="outlined" onClick={onClose}>
                Cancel
              </Button>
              <Box ml="1em">
                <Button
                  onClick={handleSelectFacility}
                  variant="contained"
                  sx={{ minWidth: "100px" }}
                >
                  Select Storage
                </Button>
              </Box>
            </Box>
          </Card>
        </AbsoluteCenter>
      </Box>
    </Modal>
  );
}
