import { useEffect, useState } from "react";
import {
  TextField,
  Card,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Modal,
  Box,
  Button,
} from "@mui/material";
import { UserController } from "../../../service/SynclisService";
import _ from "lodash";
import GeoUtility from "../../../utilities/GeoUtility";
import Grid from "@mui/material/Grid2";
import AbsoluteCenter from "../../reuseable/AbsoluteCenter/AbsoluteCenter";

const { states, countries } = GeoUtility;

export default function UpdateUserModal({
  isOpen,
  currentUser,
  roles,
  onClose,
  onUpdate,
}) {
  const [user, setUser] = useState(null);

  useEffect(() => {
    setUser(currentUser);
  }, [currentUser]);

  const handleCreateUser = async () => {
    const mUser = await UserController.update(user);
    console.log(mUser);
    if (mUser) {
      onUpdate(mUser);
    }
  };

  const handleChangeUser = (e) => {
    const { name, value } = e.target;
    const mUser = _.set(user, name, value);
    setUser({
      ...user,
      ...mUser,
    });
  };

  if (!user) {
    return null;
  }

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box position="relative" height="100%">
        <AbsoluteCenter>
          <Card>
            <Box p="2em">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Update an existing new User
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2, mb: 4 }}>
                Enter the information of the user.
              </Typography>

              <Box>
                <Box mb="1em">
                  <TextField
                    value={user.name || ""}
                    name="name"
                    onChange={handleChangeUser}
                    label="Name"
                    variant="outlined"
                    fullWidth
                  />
                </Box>
                <Box mb="1em">
                  <FormControl fullWidth>
                    <InputLabel id="role-label">Role</InputLabel>
                    <Select
                      labelId="role-label"
                      value={user.role._id || ""}
                      name="role"
                      label="Role"
                      onChange={handleChangeUser}
                    >
                      {(roles || []).map((role) => (
                        <MenuItem key={role._id} value={role._id}>
                          {role.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box mb="1em">
                  <TextField
                    value={user.phone || ""}
                    name="phone"
                    onChange={handleChangeUser}
                    label="Phone"
                    variant="outlined"
                    fullWidth
                  />
                </Box>
                <Box mb="1em">
                  <TextField
                    value={user.email || ""}
                    name="email"
                    onChange={handleChangeUser}
                    label="Email"
                    variant="outlined"
                    fullWidth
                  />
                </Box>
                <Box mb="1em">
                  <TextField
                    value={user.address?.addressLine || ""}
                    name="address.addressLine"
                    onChange={handleChangeUser}
                    label="Address"
                    variant="outlined"
                    fullWidth
                  />
                </Box>
                <Box mb="1em">
                  <TextField
                    value={user.address?.addressLine2 || ""}
                    name="address.addressLine2"
                    onChange={handleChangeUser}
                    label="Address Continued"
                    variant="outlined"
                    fullWidth
                  />
                </Box>

                <Grid container spacing={2} sx={{ marginBottom: "1em" }}>
                  <Grid size={4}>
                    <TextField
                      value={user.address?.city || ""}
                      name="address.city"
                      onChange={handleChangeUser}
                      label="City"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid size={4}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        State
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        value={user.address?.state || ""}
                        name="address.state"
                        label="State"
                        onChange={handleChangeUser}
                      >
                        {Object.keys(states).map((state) => (
                          <MenuItem key={state} value={state}>
                            {states[state]}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid size={4}>
                    <TextField
                      value={user.address?.zipcode || ""}
                      name="address.zipcode"
                      onChange={handleChangeUser}
                      label="Zipcode"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select">Country</InputLabel>
                  <Select
                    labelId="demo-simple-select"
                    value={user.address?.country || ""}
                    name="address.country"
                    label="Country"
                    onChange={handleChangeUser}
                  >
                    {countries.map((country) => (
                      <MenuItem key={country.code} value={country.code}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Box
              p="2em"
              pt="0"
              display="flex"
              alignItems="right"
              justifyContent="right"
            >
              <Button variant="outlined" onClick={onClose}>
                Cancel
              </Button>
              <Box ml="1em">
                <Button
                  onClick={handleCreateUser}
                  variant="contained"
                  sx={{ minWidth: "100px" }}
                >
                  Update User
                </Button>
              </Box>
            </Box>
          </Card>
        </AbsoluteCenter>
      </Box>
    </Modal>
  );
}
