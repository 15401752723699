import { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import SynclisLogo from "../../../assets/images/logo.svg";
import { AuthController } from "../../../service/SynclisService";
import LocalStorageUtility from "../../../utilities/LocalStorageUtility";
import { useNavigate } from "react-router-dom";
import CreateStorageModal from "../../modals/CreateStorageModal/CreateStorageModal";
import AddIcon from "@mui/icons-material/Add";
import UserMenu from "./UserMenu";
import MobileMenu from "./MobileMenu";

export default function Header() {
  const location = useLocation();
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const hasSession = user?.sessionToken;
  const [isCreateStorageModalOpen, setIsCreateStorageModalOpen] =
    useState(false);

  const handleLogout = () => {
    LocalStorageUtility.delete("synclis_session");
    AuthController.signout().catch(() => {});
    navigate("/login");
  };

  const handleToggleCreateStorageModal = () => {
    setIsCreateStorageModalOpen(!isCreateStorageModalOpen);
  };

  useEffect(() => {
    const queryUser = async () => {
      const storage = await AuthController.authThroughSession().catch((e) => {
        if (e.status) {
          handleLogout();
        }
      });
      setUser(storage);
    };

    queryUser();
  }, []);

  return (
    <>
      <Box
        borderBottom="1px solid #eee"
        display="flex"
        justifyContent="space-between"
        padding="0.25em 1em"
        height="3.25em"
        position="fixed"
        backgroundColor="white"
        width="100%"
        boxSizing="border-box"
        zIndex={100}
      >
        <Box display="flex" alignItems="center">
          <Link to="/">
            <img src={SynclisLogo} style={{ width: 140, marginRight: "1em" }} />
          </Link>
          <Link to="/inventory" style={{ textDecoration: "none" }}>
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
              }}
              borderBottom={
                location.pathname.includes("inventory")
                  ? "2px solid #24b772"
                  : "2px solid transparent"
              }
            >
              <Button>Inventory</Button>
            </Box>
          </Link>

          <Link to="/deliveries" style={{ textDecoration: "none" }}>
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
              }}
              borderBottom={
                location.pathname.includes("deliveries")
                  ? "2px solid #24b772"
                  : "2px solid transparent"
              }
            >
              <Button>Deliveries</Button>
            </Box>
          </Link>
        </Box>

        <Box display="flex" alignItems="center">
          <Button
            onClick={handleToggleCreateStorageModal}
            size="small"
            variant="outlined"
            sx={{ marginLeft: "0.5em", display: { xs: "none", md: "flex" } }}
          >
            <AddIcon /> Add New Storage
          </Button>
          {!hasSession && (
            <Link to="/login">
              <Box borderBottom="2px solid transparent">
                <Button>Login</Button>
              </Box>
            </Link>
          )}
          <UserMenu user={user} />
        </Box>
      </Box>
      <CreateStorageModal
        onClose={handleToggleCreateStorageModal}
        isOpen={isCreateStorageModalOpen}
      />
      <MobileMenu onAddStorage={handleToggleCreateStorageModal} />
    </>
  );
}
