import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export default function OAuthGoogle() {
  const [searchParams] = useSearchParams();
  const [callOnce, setCallOnce] = useState(false);

  useEffect(() => {
    if (!callOnce) {
      setCallOnce(true);
      window.location = `https://api.synclis.com/api/v1/auth/google/callback?code=${searchParams.get(
        "code"
      )}`;
    }
  }, []);

  return searchParams.get("code") ? "" : "No code provided";
}
