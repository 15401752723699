import { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Card,
  Typography,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Avatar,
} from "@mui/material";
import {
  AuthController,
  UserController,
} from "../../../service/SynclisService";
import _ from "lodash";
import GeoUtility from "../../../utilities/GeoUtility";
import Grid from "@mui/material/Grid2";
import FixedWidthContainer from "../../reuseable/FixedWidthContainer/FixedWidthContainer";

const { states, countries } = GeoUtility;

export default function ProfilePage() {
  const [user, setUser] = useState({});

  const handleChangeUser = (e) => {
    const { name, value } = e.target;
    const mUser = _.set(user, name, value);
    setUser({
      ...user,
      ...mUser,
    });
  };

  const handleUpdateUser = async () => {
    const mUser = await UserController.update(user);
  };

  useEffect(() => {
    const a = async () => {
      const user = await AuthController.authThroughSession();
      setUser(user);
    };
    a();
  }, []);

  return (
    <FixedWidthContainer>
      <Card variant="outlined" sx={{ marginBottom: "2em" }}>
        <Box p="2em" textAlign="center">
          <Avatar
            sx={{ width: 64, height: 64 }}
            alt={user.name}
            src="/static/images/avatar/1.jpg"
          />
        </Box>
      </Card>

      <Card variant="outlined">
        <CardContent>
          <Typography
            gutterBottom
            sx={{ color: "text.secondary", fontSize: 14, marginBottom: "2em" }}
          >
            Personal Information
          </Typography>

          <Grid container spacing={2} sx={{ marginBottom: "1em" }}>
            <Grid size={{ xs: 12, sm: 4 }}>
              <TextField
                value={user.name || ""}
                name="name"
                label="Name"
                onChange={handleChangeUser}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 4 }}>
              <TextField
                value={user.email || ""}
                name="email"
                onChange={handleChangeUser}
                label="Email"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 4 }}>
              <TextField
                value={user.phone || ""}
                name="phone"
                onChange={handleChangeUser}
                label="Phone"
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
          <Box mb="1em">
            <TextField
              value={user.address?.addressLine || ""}
              name="address.addressLine"
              onChange={handleChangeUser}
              label="Address"
              variant="outlined"
              fullWidth
            />
          </Box>
          <Box mb="1em">
            <TextField
              value={user.address?.addressLine2 || ""}
              name="address.addressLine2"
              onChange={handleChangeUser}
              label="Address Continued"
              variant="outlined"
              fullWidth
            />
          </Box>

          <Grid container spacing={2} sx={{ marginBottom: "1em" }}>
            <Grid size={{ xs: 12, sm: 4 }}>
              <TextField
                value={user.address?.city || ""}
                name="address.city"
                onChange={handleChangeUser}
                label="City"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 4 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">State</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  value={user.address?.state || ""}
                  name="address.state"
                  label="State"
                  onChange={handleChangeUser}
                >
                  {Object.keys(states).map((state) => (
                    <MenuItem key={state} value={state}>
                      {states[state]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid size={{ xs: 12, sm: 4 }}>
              <TextField
                value={user.address?.zipcode || ""}
                name="address.zipcode"
                onChange={handleChangeUser}
                label="Zipcode"
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>

          <FormControl fullWidth>
            <InputLabel id="demo-simple-select">Country</InputLabel>
            <Select
              labelId="demo-simple-select"
              value={user.address?.country || ""}
              name="address.country"
              label="Country"
              onChange={handleChangeUser}
            >
              {countries.map((country) => (
                <MenuItem key={country.code} value={country.code}>
                  {country.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </CardContent>
      </Card>

      <Box mt="1em">
        <Button variant="contained" onClick={handleUpdateUser}>
          Update Profile
        </Button>
      </Box>
    </FixedWidthContainer>
  );
}
