import { useState } from "react";
import {
  TextField,
  Card,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Modal,
  Box,
  Button,
} from "@mui/material";
import { FacilityController } from "../../../service/SynclisService";
import _ from "lodash";
import GeoUtility from "../../../utilities/GeoUtility";
import Grid from "@mui/material/Grid2";
import AbsoluteCenter from "../../reuseable/AbsoluteCenter/AbsoluteCenter";

const { states, countries } = GeoUtility;

export default function CreateFacilityModal({ isOpen, onClose, onCreate }) {
  const [facility, setFacility] = useState({
    address: {
      addressLine: "Tan Oak Ct",
      city: "Santa Rosa",
      state: "CA",
      country: "US",
      zipcode: 95403,
    },
    status: "ACTIVE",
    size: 10000,
    phone: "(707) 289-9610",
  });

  const handleCreateFacility = async () => {
    const mFacility = await FacilityController.create(facility);
    if (mFacility) {
      onCreate(mFacility);
    }
  };

  const handleChangeFacility = (e) => {
    const { name, value } = e.target;
    const mFacility = _.set(facility, name, value);
    setFacility({
      ...facility,
      ...mFacility,
    });
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box position="relative" height="100%">
        <AbsoluteCenter>
          <Card>
            <Box p="2em">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Create a new Facility
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2, mb: 4 }}>
                Enter the information of the new facility.
              </Typography>

              <Box>
                <Box mb="1em">
                  <TextField
                    value={facility.address?.addressLine || ""}
                    name="address.addressLine"
                    onChange={handleChangeFacility}
                    label="Address"
                    variant="outlined"
                    fullWidth
                  />
                </Box>
                <Box mb="1em">
                  <TextField
                    value={facility.address?.addressLine2 || ""}
                    name="address.addressLine2"
                    onChange={handleChangeFacility}
                    label="Address Continued"
                    variant="outlined"
                    fullWidth
                  />
                </Box>

                <Grid container spacing={2} sx={{ marginBottom: "1em" }}>
                  <Grid size={4}>
                    <TextField
                      value={facility.address?.city || ""}
                      name="address.city"
                      onChange={handleChangeFacility}
                      label="City"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid size={4}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        State
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        value={facility.address?.state || ""}
                        name="address.state"
                        label="State"
                        onChange={handleChangeFacility}
                      >
                        {Object.keys(states).map((state) => (
                          <MenuItem key={state} value={state}>
                            {states[state]}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid size={4}>
                    <TextField
                      value={facility.address?.zipcode || ""}
                      name="address.zipcode"
                      onChange={handleChangeFacility}
                      label="Zipcode"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select">Country</InputLabel>
                  <Select
                    labelId="demo-simple-select"
                    value={facility.address?.country || ""}
                    name="address.country"
                    label="Country"
                    onChange={handleChangeFacility}
                  >
                    {countries.map((country) => (
                      <MenuItem key={country.code} value={country.code}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Box mt="1em">
                <TextField
                  value={facility.phone || ""}
                  name="phone"
                  onChange={handleChangeFacility}
                  label="Phone"
                  variant="outlined"
                  fullWidth
                />
              </Box>

              <Box mt="1em">
                <TextField
                  value={facility.size || "0"}
                  name="size"
                  type="number"
                  min={0}
                  onChange={handleChangeFacility}
                  label="Facility Size (Sq Ft)"
                  variant="outlined"
                  fullWidth
                />
              </Box>
            </Box>
            <Box
              p="2em"
              pt="0"
              display="flex"
              alignItems="right"
              justifyContent="right"
            >
              <Button variant="outlined" onClick={onClose}>
                Cancel
              </Button>
              <Box ml="1em">
                <Button
                  onClick={handleCreateFacility}
                  variant="contained"
                  sx={{ minWidth: "100px" }}
                >
                  Create Facility
                </Button>
              </Box>
            </Box>
          </Card>
        </AbsoluteCenter>
      </Box>
    </Modal>
  );
}
