import { useState } from "react";
import { Card, FormControlLabel, Checkbox, Box } from "@mui/material";
import QRCode from "react-qr-code";
import { useParams } from "react-router-dom";

export default function SmallMovePickupForm() {
  const [isValueable, setIsValueable] = useState(false);
  const { id } = useParams();
  const handleSelection = () => {
    setIsValueable(!isValueable);
  };

  return (
    <>
      <Card variant="outlined">
        <Box p="2em">
          <div>
            Print the QR code, and add it to your box/parcel, so we know where
            to store it. If you do not add the QR code to the box, it will end
            up in the lost and found.
          </div>
          <Box width="200px" pb="1em" pt="1em">
            <QRCode
              size={256}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={`https://synclis.com/parcels/${id}${
                isValueable ? "?isValuable=true" : ""
              }`}
              viewBox={`0 0 256 256`}
            />
          </Box>
          <div>(https://synclis.com/parcels/{id})</div>
          <div>
            Ship the items using Fedex, UPS, USPS, to this address. When we
            receive your item it will be added to your inventory.
          </div>
          <div>
            <FormControlLabel
              value={isValueable}
              control={<Checkbox onChange={handleSelection} />}
              label="My item is valuable, store it in a secure place. (+ 29.99 mo)"
              labelPlacement="end"
            />
          </div>
        </Box>
      </Card>
    </>
  );
}
