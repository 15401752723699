import PageWrapper from "../PageWrapper/PageWrapper";
import ProfilePage from "./ProfilePage";

export default function Component() {
  return (
    <PageWrapper>
      <ProfilePage />
    </PageWrapper>
  );
}
