import { useParams } from "react-router-dom";
import {
  ItemController,
  StorageController,
} from "../../../../service/SynclisService";
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, TextField, Card } from "@mui/material";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function BasicTable({ storage }) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Owner Name</TableCell>
            <TableCell align="right">{storage?.owner?.email}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Storage Size</TableCell>
            <TableCell align="right">{storage?.size}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell align="right">{storage?.status}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Facility</TableCell>
            <TableCell align="right">
              {storage?.facility?.name || "Not Assigned"}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default function ScanParcelPage() {
  const { id } = useParams();
  const [storage, setStorage] = useState(null);
  const [itemName, setItemName] = useState("Cellphone");
  const [called, setCalled] = useState(false);
  const [createdParcel, setCreatedParcel] = useState(null);
  useEffect(() => {
    const a = async () => {
      const storage = await StorageController.getOneById(id);
      setStorage(storage);
      setCalled(true);
    };
    a();
  }, []);

  const handleUploadFile = async (e) => {
    const { files } = e.target;

    const item = await ItemController.create({
      name: itemName,
      files,
    });

    const items = storage.items;
    items.push(item);
    await StorageController.update({
      ...storage,
      items,
    });
    setCreatedParcel(item);
  };

  const handleNameChange = (e) => {
    const { value } = e.target;
    setItemName(value);
  };

  if (!id || (called && !storage)) {
    return "This Parcel doesn't exist, please check again.";
  }

  return (
    <>
      <BasicTable storage={storage} />

      {createdParcel ? (
        <Card>
          <img src={createdParcel.photo.url} style={{ width: "300px" }} />
          {createdParcel.name}
        </Card>
      ) : (
        ""
      )}

      <Box mt="1em">
        <TextField value={itemName} onChange={handleNameChange} />
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
        >
          Take Photo of Parcel
          <VisuallyHiddenInput
            type="file"
            onChange={handleUploadFile}
            multiple
          />
        </Button>
      </Box>
    </>
  );
}
