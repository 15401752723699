import { useState, useEffect } from "react";
import {
  AuthController,
  DeliveryController,
  MoveController,
} from "../../../service/SynclisService";
import AnimatedTruck from "./animated-truck.gif";

export default function MovesAndDeliveriesPage() {
  const [user, setUser] = useState({});
  const [deliveries, setDeliveries] = useState([]);
  const [moves, setMoves] = useState([]);

  useEffect(() => {
    const a = async () => {
      const deliveries = await DeliveryController.get();
      const moves = await MoveController.get();
      const user = await AuthController.authThroughSession();
      setUser(user);
      setMoves(moves);
      setDeliveries(deliveries);
    };
    a();
  }, []);

  console.log(deliveries, moves, user);

  return (
    <img
      src={AnimatedTruck}
      style={{ filter: "grayscale(0) hue-rotate(275deg)" }}
    />
  );
}
