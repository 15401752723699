import _ from "lodash";
import axios from "axios"; // Using axios to have a consistent run of external calls
export const LocalStorageUtility = {
  get(key) {
    let nameEQ = `${key}=`;
    let lStore = localStorage.getItem(key);
    let cStore = "";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0)
        cStore = c.substring(nameEQ.length, c.length);
    }
    return cStore ? cStore : lStore ? lStore : null;
  },
  create(key, value, days) {
    let expires = "";

    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = `; expires=${date.toGMTString()}`;
    } else expires = "";

    localStorage.setItem(key, value);
    document.cookie = `${key}=${value}${expires}; path=/`;
  },
  delete(key) {
    this.create(key, "", -1);
    localStorage.removeItem(key);
  },
};
const SecurityUtility = {
  SHA512(str) {
    /*
     *  Secure Hash Algorithm (SHA512)
     *  http://www.happycode.info/
     */

    function int64(msint32, lsint32) {
      this.highOrder = msint32;
      this.lowOrder = lsint32;
    }

    let H = [
      new int64(0x6a09e667, 0xf3bcc908),
      new int64(0xbb67ae85, 0x84caa73b),
      new int64(0x3c6ef372, 0xfe94f82b),
      new int64(0xa54ff53a, 0x5f1d36f1),
      new int64(0x510e527f, 0xade682d1),
      new int64(0x9b05688c, 0x2b3e6c1f),
      new int64(0x1f83d9ab, 0xfb41bd6b),
      new int64(0x5be0cd19, 0x137e2179),
    ];

    let K = [
      new int64(0x428a2f98, 0xd728ae22),
      new int64(0x71374491, 0x23ef65cd),
      new int64(0xb5c0fbcf, 0xec4d3b2f),
      new int64(0xe9b5dba5, 0x8189dbbc),
      new int64(0x3956c25b, 0xf348b538),
      new int64(0x59f111f1, 0xb605d019),
      new int64(0x923f82a4, 0xaf194f9b),
      new int64(0xab1c5ed5, 0xda6d8118),
      new int64(0xd807aa98, 0xa3030242),
      new int64(0x12835b01, 0x45706fbe),
      new int64(0x243185be, 0x4ee4b28c),
      new int64(0x550c7dc3, 0xd5ffb4e2),
      new int64(0x72be5d74, 0xf27b896f),
      new int64(0x80deb1fe, 0x3b1696b1),
      new int64(0x9bdc06a7, 0x25c71235),
      new int64(0xc19bf174, 0xcf692694),
      new int64(0xe49b69c1, 0x9ef14ad2),
      new int64(0xefbe4786, 0x384f25e3),
      new int64(0x0fc19dc6, 0x8b8cd5b5),
      new int64(0x240ca1cc, 0x77ac9c65),
      new int64(0x2de92c6f, 0x592b0275),
      new int64(0x4a7484aa, 0x6ea6e483),
      new int64(0x5cb0a9dc, 0xbd41fbd4),
      new int64(0x76f988da, 0x831153b5),
      new int64(0x983e5152, 0xee66dfab),
      new int64(0xa831c66d, 0x2db43210),
      new int64(0xb00327c8, 0x98fb213f),
      new int64(0xbf597fc7, 0xbeef0ee4),
      new int64(0xc6e00bf3, 0x3da88fc2),
      new int64(0xd5a79147, 0x930aa725),
      new int64(0x06ca6351, 0xe003826f),
      new int64(0x14292967, 0x0a0e6e70),
      new int64(0x27b70a85, 0x46d22ffc),
      new int64(0x2e1b2138, 0x5c26c926),
      new int64(0x4d2c6dfc, 0x5ac42aed),
      new int64(0x53380d13, 0x9d95b3df),
      new int64(0x650a7354, 0x8baf63de),
      new int64(0x766a0abb, 0x3c77b2a8),
      new int64(0x81c2c92e, 0x47edaee6),
      new int64(0x92722c85, 0x1482353b),
      new int64(0xa2bfe8a1, 0x4cf10364),
      new int64(0xa81a664b, 0xbc423001),
      new int64(0xc24b8b70, 0xd0f89791),
      new int64(0xc76c51a3, 0x0654be30),
      new int64(0xd192e819, 0xd6ef5218),
      new int64(0xd6990624, 0x5565a910),
      new int64(0xf40e3585, 0x5771202a),
      new int64(0x106aa070, 0x32bbd1b8),
      new int64(0x19a4c116, 0xb8d2d0c8),
      new int64(0x1e376c08, 0x5141ab53),
      new int64(0x2748774c, 0xdf8eeb99),
      new int64(0x34b0bcb5, 0xe19b48a8),
      new int64(0x391c0cb3, 0xc5c95a63),
      new int64(0x4ed8aa4a, 0xe3418acb),
      new int64(0x5b9cca4f, 0x7763e373),
      new int64(0x682e6ff3, 0xd6b2b8a3),
      new int64(0x748f82ee, 0x5defb2fc),
      new int64(0x78a5636f, 0x43172f60),
      new int64(0x84c87814, 0xa1f0ab72),
      new int64(0x8cc70208, 0x1a6439ec),
      new int64(0x90befffa, 0x23631e28),
      new int64(0xa4506ceb, 0xde82bde9),
      new int64(0xbef9a3f7, 0xb2c67915),
      new int64(0xc67178f2, 0xe372532b),
      new int64(0xca273ece, 0xea26619c),
      new int64(0xd186b8c7, 0x21c0c207),
      new int64(0xeada7dd6, 0xcde0eb1e),
      new int64(0xf57d4f7f, 0xee6ed178),
      new int64(0x06f067aa, 0x72176fba),
      new int64(0x0a637dc5, 0xa2c898a6),
      new int64(0x113f9804, 0xbef90dae),
      new int64(0x1b710b35, 0x131c471b),
      new int64(0x28db77f5, 0x23047d84),
      new int64(0x32caab7b, 0x40c72493),
      new int64(0x3c9ebe0a, 0x15c9bebc),
      new int64(0x431d67c4, 0x9c100d4c),
      new int64(0x4cc5d4be, 0xcb3e42b6),
      new int64(0x597f299c, 0xfc657e2a),
      new int64(0x5fcb6fab, 0x3ad6faec),
      new int64(0x6c44198c, 0x4a475817),
    ];

    let W = new Array(64);
    let a, b, c, d, e, f, g, h, i, j;
    let T1, T2;
    let charsize = 8;

    function utf8encode(str) {
      return unescape(encodeURIComponent(str));
    }

    function str2binb(str) {
      let bin = [];
      let mask = (1 << charsize) - 1;
      let len = str.length * charsize;

      for (let i = 0; i < len; i += charsize) {
        bin[i >> 5] |=
          (str.charCodeAt(i / charsize) & mask) << (32 - charsize - (i % 32));
      }

      return bin;
    }

    function binb2hex(binarray) {
      let hextab = "0123456789abcdef";
      let str = "";
      let length = binarray.length * 4;
      let srcByte;

      for (let i = 0; i < length; i += 1) {
        srcByte = binarray[i >> 2] >> ((3 - (i % 4)) * 8);
        str +=
          hextab.charAt((srcByte >> 4) & 0xf) + hextab.charAt(srcByte & 0xf);
      }

      return str;
    }

    function safeadd2(x, y) {
      let lsw, msw, lowOrder, highOrder;

      lsw = (x.lowOrder & 0xffff) + (y.lowOrder & 0xffff);
      msw = (x.lowOrder >>> 16) + (y.lowOrder >>> 16) + (lsw >>> 16);
      lowOrder = ((msw & 0xffff) << 16) | (lsw & 0xffff);

      lsw = (x.highOrder & 0xffff) + (y.highOrder & 0xffff) + (msw >>> 16);
      msw = (x.highOrder >>> 16) + (y.highOrder >>> 16) + (lsw >>> 16);
      highOrder = ((msw & 0xffff) << 16) | (lsw & 0xffff);

      return new int64(highOrder, lowOrder);
    }

    function safeadd4(a, b, c, d) {
      let lsw, msw, lowOrder, highOrder;

      lsw =
        (a.lowOrder & 0xffff) +
        (b.lowOrder & 0xffff) +
        (c.lowOrder & 0xffff) +
        (d.lowOrder & 0xffff);
      msw =
        (a.lowOrder >>> 16) +
        (b.lowOrder >>> 16) +
        (c.lowOrder >>> 16) +
        (d.lowOrder >>> 16) +
        (lsw >>> 16);
      lowOrder = ((msw & 0xffff) << 16) | (lsw & 0xffff);

      lsw =
        (a.highOrder & 0xffff) +
        (b.highOrder & 0xffff) +
        (c.highOrder & 0xffff) +
        (d.highOrder & 0xffff) +
        (msw >>> 16);
      msw =
        (a.highOrder >>> 16) +
        (b.highOrder >>> 16) +
        (c.highOrder >>> 16) +
        (d.highOrder >>> 16) +
        (lsw >>> 16);
      highOrder = ((msw & 0xffff) << 16) | (lsw & 0xffff);

      return new int64(highOrder, lowOrder);
    }

    function safeadd5(a, b, c, d, e) {
      let lsw, msw, lowOrder, highOrder;

      lsw =
        (a.lowOrder & 0xffff) +
        (b.lowOrder & 0xffff) +
        (c.lowOrder & 0xffff) +
        (d.lowOrder & 0xffff) +
        (e.lowOrder & 0xffff);
      msw =
        (a.lowOrder >>> 16) +
        (b.lowOrder >>> 16) +
        (c.lowOrder >>> 16) +
        (d.lowOrder >>> 16) +
        (e.lowOrder >>> 16) +
        (lsw >>> 16);
      lowOrder = ((msw & 0xffff) << 16) | (lsw & 0xffff);

      lsw =
        (a.highOrder & 0xffff) +
        (b.highOrder & 0xffff) +
        (c.highOrder & 0xffff) +
        (d.highOrder & 0xffff) +
        (e.highOrder & 0xffff) +
        (msw >>> 16);
      msw =
        (a.highOrder >>> 16) +
        (b.highOrder >>> 16) +
        (c.highOrder >>> 16) +
        (d.highOrder >>> 16) +
        (e.highOrder >>> 16) +
        (lsw >>> 16);
      highOrder = ((msw & 0xffff) << 16) | (lsw & 0xffff);

      return new int64(highOrder, lowOrder);
    }

    function maj(x, y, z) {
      return new int64(
        (x.highOrder & y.highOrder) ^
          (x.highOrder & z.highOrder) ^
          (y.highOrder & z.highOrder),
        (x.lowOrder & y.lowOrder) ^
          (x.lowOrder & z.lowOrder) ^
          (y.lowOrder & z.lowOrder)
      );
    }

    function ch(x, y, z) {
      return new int64(
        (x.highOrder & y.highOrder) ^ (~x.highOrder & z.highOrder),
        (x.lowOrder & y.lowOrder) ^ (~x.lowOrder & z.lowOrder)
      );
    }

    function rotr(x, n) {
      if (n <= 32) {
        return new int64(
          (x.highOrder >>> n) | (x.lowOrder << (32 - n)),
          (x.lowOrder >>> n) | (x.highOrder << (32 - n))
        );
      } else {
        return new int64(
          (x.lowOrder >>> n) | (x.highOrder << (32 - n)),
          (x.highOrder >>> n) | (x.lowOrder << (32 - n))
        );
      }
    }

    function sigma0(x) {
      let rotr28 = rotr(x, 28);
      let rotr34 = rotr(x, 34);
      let rotr39 = rotr(x, 39);

      return new int64(
        rotr28.highOrder ^ rotr34.highOrder ^ rotr39.highOrder,
        rotr28.lowOrder ^ rotr34.lowOrder ^ rotr39.lowOrder
      );
    }

    function sigma1(x) {
      let rotr14 = rotr(x, 14);
      let rotr18 = rotr(x, 18);
      let rotr41 = rotr(x, 41);

      return new int64(
        rotr14.highOrder ^ rotr18.highOrder ^ rotr41.highOrder,
        rotr14.lowOrder ^ rotr18.lowOrder ^ rotr41.lowOrder
      );
    }

    function gamma0(x) {
      let rotr1 = rotr(x, 1),
        rotr8 = rotr(x, 8),
        shr7 = shr(x, 7);

      return new int64(
        rotr1.highOrder ^ rotr8.highOrder ^ shr7.highOrder,
        rotr1.lowOrder ^ rotr8.lowOrder ^ shr7.lowOrder
      );
    }

    function gamma1(x) {
      let rotr19 = rotr(x, 19);
      let rotr61 = rotr(x, 61);
      let shr6 = shr(x, 6);

      return new int64(
        rotr19.highOrder ^ rotr61.highOrder ^ shr6.highOrder,
        rotr19.lowOrder ^ rotr61.lowOrder ^ shr6.lowOrder
      );
    }

    function shr(x, n) {
      if (n <= 32) {
        return new int64(
          x.highOrder >>> n,
          (x.lowOrder >>> n) | (x.highOrder << (32 - n))
        );
      } else {
        return new int64(0, x.highOrder << (32 - n));
      }
    }

    str = utf8encode(str);
    let strlen = str.length * charsize;
    str = str2binb(str);

    str[strlen >> 5] |= 0x80 << (24 - (strlen % 32));
    str[(((strlen + 128) >> 10) << 5) + 31] = strlen;

    for (let i = 0; i < str.length; i += 32) {
      a = H[0];
      b = H[1];
      c = H[2];
      d = H[3];
      e = H[4];
      f = H[5];
      g = H[6];
      h = H[7];

      for (let j = 0; j < 80; j++) {
        if (j < 16) {
          W[j] = new int64(str[j * 2 + i], str[j * 2 + i + 1]);
        } else {
          W[j] = safeadd4(
            gamma1(W[j - 2]),
            W[j - 7],
            gamma0(W[j - 15]),
            W[j - 16]
          );
        }

        T1 = safeadd5(h, sigma1(e), ch(e, f, g), K[j], W[j]);
        T2 = safeadd2(sigma0(a), maj(a, b, c));
        h = g;
        g = f;
        f = e;
        e = safeadd2(d, T1);
        d = c;
        c = b;
        b = a;
        a = safeadd2(T1, T2);
      }

      H[0] = safeadd2(a, H[0]);
      H[1] = safeadd2(b, H[1]);
      H[2] = safeadd2(c, H[2]);
      H[3] = safeadd2(d, H[3]);
      H[4] = safeadd2(e, H[4]);
      H[5] = safeadd2(f, H[5]);
      H[6] = safeadd2(g, H[6]);
      H[7] = safeadd2(h, H[7]);
    }

    let binarray = [];
    for (let i = 0; i < H.length; i++) {
      binarray.push(H[i].highOrder);
      binarray.push(H[i].lowOrder);
    }
    return binb2hex(binarray);
  },
};
function InternalAPIService() {
  this.baseUrl = "https://api.synclis.com"; //"http://localhost:3010";
  this.port = "";
  this.REDIRECT_URI = "http://localhost:3000";
}

InternalAPIService.prototype.getBaseUrl = function getBaseUrl() {
  return this.baseUrl;
};

InternalAPIService.prototype.ajax = async (
  method,
  extendedUrl,
  data = {},
  headerOverride = {}
) => {
  const session = LocalStorageUtility.get("synclis_session") || false;
  const baseUrl = new InternalAPIService().baseUrl;

  return new Promise(async (resolve, reject) => {
    let result = null;
    if (method === "POST" || method === "PUT" || method === "PATCH") {
      result = await axios[_.toLower(method)](
        `${baseUrl}/api/v1${extendedUrl}`,
        data,
        {
          headers: _.merge(headerOverride, {
            Authorization: `Bearer ${session}`,
            "Content-Type": "application/json",
          }),
        }
      ).catch((e) => {
        if (!_.get(e, "response.status")) {
          if (e.toString().includes("Network Error")) {
            return reject({ status: 0, data: "Network Error" });
          }
        }
        const { response, body } = e;
        reject({ status: response.status, data: body });
      });
    } else {
      result = await axios({
        method,
        url: `${baseUrl}/api/v1${extendedUrl}`,
        body: data,
        headers: _.merge(headerOverride, {
          Authorization: `Bearer ${session}`,
          "Content-Type": "application/json",
        }),
      }).catch((e) => {
        if (!_.get(e, "response.status")) {
          if (e.toString().includes("Network Error")) {
            return reject({ status: 0, data: "Network Error" });
          }
        }
        const { response, body } = e;
        reject({ status: response.status, data: body });
      });
    }

    if (result && result.status >= 200 && result.status < 299) {
      resolve(result.data);
    }
  });
};

export const APIService = new InternalAPIService();

function InternalAuthController() {
  this.model = {
    name: "",
    email: "",
    isAuthed: false,
  };
}

// Quickly Auth the user by session
InternalAuthController.prototype.auth = function auth() {
  let context = this;

  if (this.model.isAuthed) {
    return this.model;
  }

  return APIService.ajax("GET", "/user/login/session").then(
    (res) => {
      context.model = _.merge(context.model, res);
      context.model.isAuthed = true;

      return Promise.resolve(context.model);
    },
    (err) => {
      return Promise.reject(err);
    }
  );
};

InternalAuthController.prototype.bind = function bind() {
  let context = this;

  return context.model;
};

InternalAuthController.prototype.authThroughSession =
  function authThroughSession() {
    let context = this;

    if (context.isAuthed) return Promise.resolve(context.model);

    if (!context.isAuthed)
      return APIService.ajax("GET", "/user/login/session").then(
        (res) => {
          context.model = _.merge(context.model, res);
          context.model.isAuthed = true;

          return Promise.resolve(context.model);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
  };

InternalAuthController.prototype.signin = function signin(credentials) {
  let context = this;

  credentials.password = SecurityUtility.SHA512(credentials.password);

  return APIService.ajax("POST", "/user/login", credentials).then(
    (res) => {
      if (res.status !== 400 || res.status !== 401) {
        LocalStorageUtility.create("synclis_session", res.sessionToken);
        context.model = _.merge(context.model, res);
        context.model.isAuthed = true;
      }

      return Promise.resolve(context.model);
    },
    (err) => {
      return Promise.reject(err);
    }
  );
};

InternalAuthController.prototype.signout = function signout() {
  let context = this;

  FacilityController.model = [];
  ItemController.model = [];
  StorageController.model = [];
  MoveController.model = [];
  DeliveryController.model = [];
  RoleController.model = [];
  return APIService.ajax("POST", "/user/signout").then(() => {
    LocalStorageUtility.delete("synclis_session");
    context.model = {
      name: "",
      email: "",
      isAuthed: false,
    };
  });
};

InternalAuthController.prototype.sendPasswordReset =
  function sendPasswordReset({ email }) {
    if (!email) {
      return Promise.reject("No email sent");
    }

    return APIService.ajax("POST", "/auth/send-reset-password", { email });
  };

InternalAuthController.prototype.resetPassword = function resetPassword({
  password,
  passwordResetCode,
}) {
  if (!password || !passwordResetCode) {
    return Promise.reject("Missing data");
  }

  const hashedPassword = SecurityUtility.SHA512(password);

  return APIService.ajax("POST", "/auth/reset-password", {
    password: hashedPassword,
    passwordResetCode,
  });
};

export const AuthController = new InternalAuthController();
function InternalUserController() {
  this.model = [];
  this.tableIsEmpty = null; // To determine whether or not the API was called.
}

InternalUserController.prototype.get = function get() {
  if (!_.isEmpty(this.model)) {
    return Promise.resolve(this.model);
  }

  return APIService.ajax("GET", "/user").then((results) => {
    // The database is empty, mark this and return the empty model.
    if (_.isEmpty(results)) {
      this.tableIsEmpty = true;
      return this.model;
    }

    // Prevent duplicates in the model array.
    results.forEach((result) => {
      let isInModel = false;
      this.model.forEach((model) => {
        if (result._id === model._id) {
          isInModel = true;
        }
      });

      if (!isInModel) {
        this.model.push(result);
      }
    });
    return this.model;
  });
};

InternalUserController.prototype.getOneById = function getOneById(_id) {
  // We're only looking for ID, if its not here...
  if (!_id) {
    return null;
  }

  // If we find it in the model, return that, it's faster.
  if (!_.isEmpty(this.model)) {
    const one = _.find(this.model, { _id });
    if (one) return Promise.resolve(one);
  }

  // If it's not in the model, then lets call the server for it.
  return APIService.ajax("GET", `/user/${_id}`).then((result) => {
    // Add to model array, prevent duplicates in the model array.
    const found = this.model.find((model) => result._id === model._id);
    if (!found) this.model.push(result);

    return result;
  });
};

InternalUserController.prototype.getOne = function get(props = {}) {
  // If we find it in the model, return that, it's faster.
  if (!_.isEmpty(this.model)) {
    const one = _.find(this.model, props);
    if (one) return Promise.resolve(one);
  }

  // If it's not in the model, then lets call the server for it.
  return APIService.ajax("GET", "/user").then((result) => {
    // Add to model array, prevent duplicates in the model array.
    const found = this.model.find((model) => result._id === model._id);
    if (!found) this.model.push(result);

    return result;
  });
};

InternalUserController.prototype.create = async function create(payload) {
  if (payload.password)
    payload.password = SecurityUtility.SHA512(payload.password);

  const formData = new FormData();
  Object.keys(payload).forEach((key) => {
    if (key !== "photo") formData.append(key, payload[key]);
  });

  for (let i = 0; i < (payload.files || []).length; i++) {
    formData.append("photo", payload.files[i]);
  }

  const session = LocalStorageUtility.get("synclis_session") || false;
  const fetchOptions = {
    method: "POST",
    body: formData,
    headers: {
      Authorization: `Bearer ${session}`,
    },
  };

  const result = await fetch(`${APIService.baseUrl}/api/v1/user`, fetchOptions);
  if (result.ok) {
    const user = await result.json();
    LocalStorageUtility.create("synclis_session", user.sessionToken);
    return Promise.resolve(user);
  }
  return Promise.reject();
};

InternalUserController.prototype.update = function update(payload) {
  // We're modifying multiple.
  if (_.isArray(payload)) {
    return APIService.ajax("PUT", "/user", payload).then((model) => {
      payload.forEach((item) => {
        const index = _.findIndex(this.model, { _id: item._id });
        if (index !== undefined || index !== null) {
          this.model[index] = payload;
        }
      });
    });
  }

  // We're modifying one.
  else {
    return APIService.ajax("PUT", `/user/${payload._id}`, payload).then(
      (model) => {
        // Replace the item that was updated.
        const index = _.findIndex(this.model, { _id: payload._id });
        if (index !== undefined || index !== null) {
          this.model[index] = payload;
        }
      }
    );
  }
};

InternalUserController.prototype.remove = function remove(model) {
  let id = model;

  if (_.isObject(model)) {
    id = model._id;
  }

  return APIService.ajax("DELETE", `/user/${id}`).then(() => {
    _.remove(this.model, { _id: id });
  });
};

export const UserController = new InternalUserController();
function InternalFacilityController() {
  this.model = [];
  this.tableIsEmpty = null; // To determine whether or not the API was called.
}

InternalFacilityController.prototype.get = function get() {
  if (!_.isEmpty(this.model)) {
    return Promise.resolve(this.model);
  }

  return APIService.ajax("GET", "/facility").then((results) => {
    // The database is empty, mark this and return the empty model.
    if (_.isEmpty(results)) {
      this.tableIsEmpty = true;
      return this.model;
    }

    // Prevent duplicates in the model array.
    results.forEach((result) => {
      let isInModel = false;
      this.model.forEach((model) => {
        if (result._id === model._id) {
          isInModel = true;
        }
      });

      if (!isInModel) {
        this.model.push(result);
      }
    });
    return this.model;
  });
};

InternalFacilityController.prototype.getOneById = function getOneById(_id) {
  // We're only looking for ID, if its not here...
  if (!_id) {
    return null;
  }

  // If we find it in the model, return that, it's faster.
  if (!_.isEmpty(this.model)) {
    const one = _.find(this.model, { _id });
    if (one) return Promise.resolve(one);
  }

  // If it's not in the model, then lets call the server for it.
  return APIService.ajax("GET", `/facility/${_id}`).then((result) => {
    // Add to model array, prevent duplicates in the model array.
    const found = this.model.find((model) => result._id === model._id);
    if (!found) this.model.push(result);

    return result;
  });
};

InternalFacilityController.prototype.getOne = function get(props = {}) {
  // If we find it in the model, return that, it's faster.
  if (!_.isEmpty(this.model)) {
    const one = _.find(this.model, props);
    if (one) return Promise.resolve(one);
  }

  // If it's not in the model, then lets call the server for it.
  return APIService.ajax("GET", "/facility").then((result) => {
    // Add to model array, prevent duplicates in the model array.
    const found = this.model.find((model) => result._id === model._id);
    if (!found) this.model.push(result);

    return result;
  });
};

InternalFacilityController.prototype.create = async function create(payload) {
  return APIService.ajax("POST", "/facility", payload).then((model) => {
    this.model.push(payload);
    return model;
  });
};

InternalFacilityController.prototype.update = function update(payload) {
  // We're modifying multiple.
  if (_.isArray(payload)) {
    return APIService.ajax("PUT", "/facility", payload).then((model) => {
      payload.forEach((item) => {
        const index = _.findIndex(this.model, { _id: item._id });
        if (index !== undefined || index !== null) {
          this.model[index] = payload;
        }
      });
    });
  }

  // We're modifying one.
  else {
    return APIService.ajax("PUT", `/facility/${payload._id}`, payload).then(
      (model) => {
        // Replace the item that was updated.
        const index = _.findIndex(this.model, { _id: payload._id });
        if (index !== undefined || index !== null) {
          this.model[index] = payload;
        }
      }
    );
  }
};

InternalFacilityController.prototype.remove = function remove(model) {
  let id = model;

  if (_.isObject(model)) {
    id = model._id;
  }

  return APIService.ajax("DELETE", `/facility/${id}`).then(() => {
    _.remove(this.model, { _id: id });
  });
};

export const FacilityController = new InternalFacilityController();
function InternalItemController() {
  this.model = [];
  this.tableIsEmpty = null; // To determine whether or not the API was called.
}

InternalItemController.prototype.get = function get() {
  if (!_.isEmpty(this.model)) {
    return Promise.resolve(this.model);
  }

  return APIService.ajax("GET", "/item").then((results) => {
    // The database is empty, mark this and return the empty model.
    if (_.isEmpty(results)) {
      this.tableIsEmpty = true;
      return this.model;
    }

    // Prevent duplicates in the model array.
    results.forEach((result) => {
      let isInModel = false;
      this.model.forEach((model) => {
        if (result._id === model._id) {
          isInModel = true;
        }
      });

      if (!isInModel) {
        this.model.push(result);
      }
    });
    return this.model;
  });
};

InternalItemController.prototype.getOneById = function getOneById(_id) {
  // We're only looking for ID, if its not here...
  if (!_id) {
    return null;
  }

  // If we find it in the model, return that, it's faster.
  if (!_.isEmpty(this.model)) {
    const one = _.find(this.model, { _id });
    if (one) return Promise.resolve(one);
  }

  // If it's not in the model, then lets call the server for it.
  return APIService.ajax("GET", `/item/${_id}`).then((result) => {
    // Add to model array, prevent duplicates in the model array.
    const found = this.model.find((model) => result._id === model._id);
    if (!found) this.model.push(result);

    return result;
  });
};

InternalItemController.prototype.getOne = function get(props = {}) {
  // If we find it in the model, return that, it's faster.
  if (!_.isEmpty(this.model)) {
    const one = _.find(this.model, props);
    if (one) return Promise.resolve(one);
  }

  // If it's not in the model, then lets call the server for it.
  return APIService.ajax("GET", "/item").then((result) => {
    // Add to model array, prevent duplicates in the model array.
    const found = this.model.find((model) => result._id === model._id);
    if (!found) this.model.push(result);

    return result;
  });
};

InternalItemController.prototype.create = async function create(payload) {
  const user = await AuthController.auth();
  if (user) {
    payload["owner"] = user._id;
  } else {
    return Promise.reject({ data: ["Unable to retrieve user"] });
  }

  const formData = new FormData();
  Object.keys(payload).forEach((key) => {
    if (key !== "photo") formData.append(key, payload[key]);
  });

  for (let i = 0; i < (payload.files || []).length; i++) {
    formData.append("photo", payload.files[i]);
  }

  const session = LocalStorageUtility.get("synclis_session") || false;
  const fetchOptions = {
    method: "POST",
    body: formData,
    headers: {
      Authorization: `Bearer ${session}`,
    },
  };

  const result = await fetch(`${APIService.baseUrl}/api/v1/item`, fetchOptions);
  if (result.ok) return result.json();
  return Promise.reject();
};

InternalItemController.prototype.update = function update(payload) {
  // We're modifying multiple.
  if (_.isArray(payload)) {
    return APIService.ajax("PUT", "/item", payload).then((model) => {
      payload.forEach((item) => {
        const index = _.findIndex(this.model, { _id: item._id });
        if (index !== undefined || index !== null) {
          this.model[index] = payload;
        }
      });
    });
  }

  // We're modifying one.
  else {
    return APIService.ajax("PUT", `/item/${payload._id}`, payload).then(
      (model) => {
        // Replace the item that was updated.
        const index = _.findIndex(this.model, { _id: payload._id });
        if (index !== undefined || index !== null) {
          this.model[index] = payload;
        }
      }
    );
  }
};

InternalItemController.prototype.remove = function remove(model) {
  let id = model;

  if (_.isObject(model)) {
    id = model._id;
  }

  return APIService.ajax("DELETE", `/item/${id}`).then(() => {
    _.remove(this.model, { _id: id });
  });
};

export const ItemController = new InternalItemController();
function InternalStorageController() {
  this.model = [];
  this.tableIsEmpty = null; // To determine whether or not the API was called.
}

InternalStorageController.prototype.get = function get() {
  if (!_.isEmpty(this.model)) {
    return Promise.resolve(this.model);
  }

  return APIService.ajax("GET", "/storage").then((results) => {
    // The database is empty, mark this and return the empty model.
    if (_.isEmpty(results)) {
      this.tableIsEmpty = true;
      return this.model;
    }

    // Prevent duplicates in the model array.
    results.forEach((result) => {
      let isInModel = false;
      this.model.forEach((model) => {
        if (result._id === model._id) {
          isInModel = true;
        }
      });

      if (!isInModel) {
        this.model.push(result);
      }
    });
    return this.model;
  });
};

InternalStorageController.prototype.getOneById = function getOneById(_id) {
  // We're only looking for ID, if its not here...
  if (!_id) {
    return null;
  }

  // If we find it in the model, return that, it's faster.
  if (!_.isEmpty(this.model)) {
    const one = _.find(this.model, { _id });
    if (one) return Promise.resolve(one);
  }

  // If it's not in the model, then lets call the server for it.
  return APIService.ajax("GET", `/storage/${_id}`).then((result) => {
    // Add to model array, prevent duplicates in the model array.
    const found = this.model.find((model) => result._id === model._id);
    if (!found) this.model.push(result);

    return result;
  });
};

InternalStorageController.prototype.getOne = function get(props = {}) {
  // If we find it in the model, return that, it's faster.
  if (!_.isEmpty(this.model)) {
    const one = _.find(this.model, props);
    if (one) return Promise.resolve(one);
  }

  // If it's not in the model, then lets call the server for it.
  return APIService.ajax("GET", "/storage").then((result) => {
    // Add to model array, prevent duplicates in the model array.
    const found = this.model.find((model) => result._id === model._id);
    if (!found) this.model.push(result);

    return result;
  });
};

InternalStorageController.prototype.create = async function create(payload) {
  const user = await AuthController.auth();
  if (user) {
    payload["owner"] = user._id;
  } else {
    return Promise.reject({ data: ["Unable to retrieve user"] });
  }

  return APIService.ajax("POST", "/storage", payload).then((model) => {
    this.model.push(payload);
    return model;
  });
};

InternalStorageController.prototype.update = function update(payload) {
  // We're modifying multiple.
  if (_.isArray(payload)) {
    return APIService.ajax("PUT", "/storage", payload).then((model) => {
      payload.forEach((item) => {
        const index = _.findIndex(this.model, { _id: item._id });
        if (index !== undefined || index !== null) {
          this.model[index] = payload;
        }
      });
    });
  }

  // We're modifying one.
  else {
    return APIService.ajax("PUT", `/storage/${payload._id}`, payload).then(
      (model) => {
        // Replace the item that was updated.
        const index = _.findIndex(this.model, { _id: payload._id });
        if (index !== undefined || index !== null) {
          this.model[index] = payload;
        }
      }
    );
  }
};

InternalStorageController.prototype.remove = function remove(model) {
  let id = model;

  if (_.isObject(model)) {
    id = model._id;
  }

  return APIService.ajax("DELETE", `/storage/${id}`).then(() => {
    _.remove(this.model, { _id: id });
  });
};

export const StorageController = new InternalStorageController();
function InternalMoveController() {
  this.model = [];
  this.tableIsEmpty = null; // To determine whether or not the API was called.
}

InternalMoveController.prototype.get = function get() {
  if (!_.isEmpty(this.model)) {
    return Promise.resolve(this.model);
  }

  return APIService.ajax("GET", "/move").then((results) => {
    // The database is empty, mark this and return the empty model.
    if (_.isEmpty(results)) {
      this.tableIsEmpty = true;
      return this.model;
    }

    // Prevent duplicates in the model array.
    results.forEach((result) => {
      let isInModel = false;
      this.model.forEach((model) => {
        if (result._id === model._id) {
          isInModel = true;
        }
      });

      if (!isInModel) {
        this.model.push(result);
      }
    });
    return this.model;
  });
};

InternalMoveController.prototype.getOneById = function getOneById(_id) {
  // We're only looking for ID, if its not here...
  if (!_id) {
    return null;
  }

  // If we find it in the model, return that, it's faster.
  if (!_.isEmpty(this.model)) {
    const one = _.find(this.model, { _id });
    if (one) return Promise.resolve(one);
  }

  // If it's not in the model, then lets call the server for it.
  return APIService.ajax("GET", `/move/${_id}`).then((result) => {
    // Add to model array, prevent duplicates in the model array.
    const found = this.model.find((model) => result._id === model._id);
    if (!found) this.model.push(result);

    return result;
  });
};

InternalMoveController.prototype.getOne = function get(props = {}) {
  // If we find it in the model, return that, it's faster.
  if (!_.isEmpty(this.model)) {
    const one = _.find(this.model, props);
    if (one) return Promise.resolve(one);
  }

  // If it's not in the model, then lets call the server for it.
  return APIService.ajax("GET", "/move").then((result) => {
    // Add to model array, prevent duplicates in the model array.
    const found = this.model.find((model) => result._id === model._id);
    if (!found) this.model.push(result);

    return result;
  });
};

InternalMoveController.prototype.create = async function create(payload) {
  const user = await AuthController.auth();
  if (user) {
    payload["customer"] = user._id;
  } else {
    return Promise.reject({ data: ["Unable to retrieve user"] });
  }

  return APIService.ajax("POST", "/move", payload).then((model) => {
    this.model.push(payload);
    return model;
  });
};

InternalMoveController.prototype.update = function update(payload) {
  // We're modifying multiple.
  if (_.isArray(payload)) {
    return APIService.ajax("PUT", "/move", payload).then((model) => {
      payload.forEach((item) => {
        const index = _.findIndex(this.model, { _id: item._id });
        if (index !== undefined || index !== null) {
          this.model[index] = payload;
        }
      });
    });
  }

  // We're modifying one.
  else {
    return APIService.ajax("PUT", `/move/${payload._id}`, payload).then(
      (model) => {
        // Replace the item that was updated.
        const index = _.findIndex(this.model, { _id: payload._id });
        if (index !== undefined || index !== null) {
          this.model[index] = payload;
        }
      }
    );
  }
};

InternalMoveController.prototype.remove = function remove(model) {
  let id = model;

  if (_.isObject(model)) {
    id = model._id;
  }

  return APIService.ajax("DELETE", `/move/${id}`).then(() => {
    _.remove(this.model, { _id: id });
  });
};

export const MoveController = new InternalMoveController();
function InternalDeliveryController() {
  this.model = [];
  this.tableIsEmpty = null; // To determine whether or not the API was called.
}

InternalDeliveryController.prototype.get = function get() {
  if (!_.isEmpty(this.model)) {
    return Promise.resolve(this.model);
  }

  return APIService.ajax("GET", "/delivery").then((results) => {
    // The database is empty, mark this and return the empty model.
    if (_.isEmpty(results)) {
      this.tableIsEmpty = true;
      return this.model;
    }

    // Prevent duplicates in the model array.
    results.forEach((result) => {
      let isInModel = false;
      this.model.forEach((model) => {
        if (result._id === model._id) {
          isInModel = true;
        }
      });

      if (!isInModel) {
        this.model.push(result);
      }
    });
    return this.model;
  });
};

InternalDeliveryController.prototype.getOneById = function getOneById(_id) {
  // We're only looking for ID, if its not here...
  if (!_id) {
    return null;
  }

  // If we find it in the model, return that, it's faster.
  if (!_.isEmpty(this.model)) {
    const one = _.find(this.model, { _id });
    if (one) return Promise.resolve(one);
  }

  // If it's not in the model, then lets call the server for it.
  return APIService.ajax("GET", `/delivery/${_id}`).then((result) => {
    // Add to model array, prevent duplicates in the model array.
    const found = this.model.find((model) => result._id === model._id);
    if (!found) this.model.push(result);

    return result;
  });
};

InternalDeliveryController.prototype.getOne = function get(props = {}) {
  // If we find it in the model, return that, it's faster.
  if (!_.isEmpty(this.model)) {
    const one = _.find(this.model, props);
    if (one) return Promise.resolve(one);
  }

  // If it's not in the model, then lets call the server for it.
  return APIService.ajax("GET", "/delivery").then((result) => {
    // Add to model array, prevent duplicates in the model array.
    const found = this.model.find((model) => result._id === model._id);
    if (!found) this.model.push(result);

    return result;
  });
};

InternalDeliveryController.prototype.create = async function create(payload) {
  const user = await AuthController.auth();
  if (user) {
    payload["customer"] = user._id;
  } else {
    return Promise.reject({ data: ["Unable to retrieve user"] });
  }

  return APIService.ajax("POST", "/delivery", payload).then((model) => {
    this.model.push(payload);
    return model;
  });
};

InternalDeliveryController.prototype.update = function update(payload) {
  // We're modifying multiple.
  if (_.isArray(payload)) {
    return APIService.ajax("PUT", "/delivery", payload).then((model) => {
      payload.forEach((item) => {
        const index = _.findIndex(this.model, { _id: item._id });
        if (index !== undefined || index !== null) {
          this.model[index] = payload;
        }
      });
    });
  }

  // We're modifying one.
  else {
    return APIService.ajax("PUT", `/delivery/${payload._id}`, payload).then(
      (model) => {
        // Replace the item that was updated.
        const index = _.findIndex(this.model, { _id: payload._id });
        if (index !== undefined || index !== null) {
          this.model[index] = payload;
        }
      }
    );
  }
};

InternalDeliveryController.prototype.remove = function remove(model) {
  let id = model;

  if (_.isObject(model)) {
    id = model._id;
  }

  return APIService.ajax("DELETE", `/delivery/${id}`).then(() => {
    _.remove(this.model, { _id: id });
  });
};

export const DeliveryController = new InternalDeliveryController();
function InternalRoleController() {
  this.model = [];
  this.tableIsEmpty = null; // To determine whether or not the API was called.
}

InternalRoleController.prototype.get = function get() {
  if (!_.isEmpty(this.model)) {
    return Promise.resolve(this.model);
  }

  return APIService.ajax("GET", "/role").then((results) => {
    // The database is empty, mark this and return the empty model.
    if (_.isEmpty(results)) {
      this.tableIsEmpty = true;
      return this.model;
    }

    // Prevent duplicates in the model array.
    results.forEach((result) => {
      let isInModel = false;
      this.model.forEach((model) => {
        if (result._id === model._id) {
          isInModel = true;
        }
      });

      if (!isInModel) {
        this.model.push(result);
      }
    });
    return this.model;
  });
};

InternalRoleController.prototype.getOneById = function getOneById(_id) {
  // We're only looking for ID, if its not here...
  if (!_id) {
    return null;
  }

  // If we find it in the model, return that, it's faster.
  if (!_.isEmpty(this.model)) {
    const one = _.find(this.model, { _id });
    if (one) return Promise.resolve(one);
  }

  // If it's not in the model, then lets call the server for it.
  return APIService.ajax("GET", `/role/${_id}`).then((result) => {
    // Add to model array, prevent duplicates in the model array.
    const found = this.model.find((model) => result._id === model._id);
    if (!found) this.model.push(result);

    return result;
  });
};

InternalRoleController.prototype.getOne = function get(props = {}) {
  // If we find it in the model, return that, it's faster.
  if (!_.isEmpty(this.model)) {
    const one = _.find(this.model, props);
    if (one) return Promise.resolve(one);
  }

  // If it's not in the model, then lets call the server for it.
  return APIService.ajax("GET", "/role").then((result) => {
    // Add to model array, prevent duplicates in the model array.
    const found = this.model.find((model) => result._id === model._id);
    if (!found) this.model.push(result);

    return result;
  });
};

InternalRoleController.prototype.create = async function create(payload) {
  const user = await AuthController.auth();
  if (user) {
    payload["owner"] = user._id;
  } else {
    return Promise.reject({ data: ["Unable to retrieve user"] });
  }

  return APIService.ajax("POST", "/role", payload).then((model) => {
    this.model.push(payload);
    return model;
  });
};

InternalRoleController.prototype.update = function update(payload) {
  // We're modifying multiple.
  if (_.isArray(payload)) {
    return APIService.ajax("PUT", "/role", payload).then((model) => {
      payload.forEach((item) => {
        const index = _.findIndex(this.model, { _id: item._id });
        if (index !== undefined || index !== null) {
          this.model[index] = payload;
        }
      });
    });
  }

  // We're modifying one.
  else {
    return APIService.ajax("PUT", `/role/${payload._id}`, payload).then(
      (model) => {
        // Replace the item that was updated.
        const index = _.findIndex(this.model, { _id: payload._id });
        if (index !== undefined || index !== null) {
          this.model[index] = payload;
        }
      }
    );
  }
};

InternalRoleController.prototype.remove = function remove(model) {
  let id = model;

  if (_.isObject(model)) {
    id = model._id;
  }

  return APIService.ajax("DELETE", `/role/${id}`).then(() => {
    _.remove(this.model, { _id: id });
  });
};

export const RoleController = new InternalRoleController();
