import { useState } from "react";
import {
  Card,
  Modal,
  Typography,
  Box,
  Button,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import { StorageController } from "../../../service/SynclisService";
import ScrollingContainer from "../../reuseable/ScrollingContainer/ScrollingContainer";
import SmallCubeIllustration from "./cube.svg";
import MediumCubeIllustration from "./cube2.svg";
import LargeCubeIllustration from "./cube3.svg";
import useMediaQuery from "@mui/material/useMediaQuery";
import Payment from "../../reuseable/Payment/Payment";

export default function CreateStorageModal({ isOpen, onClose }) {
  const [term, setTerm] = useState("MONTHLY");
  const [size, setSize] = useState("SMALL");
  const [currentCost, setCurrentCost] = useState(100);
  const smallScreen = useMediaQuery("(min-width:600px)");

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const handleCreateStorage = async () => {
    return await StorageController.create({
      term,
      size,
    });
  };

  const handleDurationChange = (_, duration) => {
    if (duration) {
      setTerm(duration);

      const cost = sizeMap.find((item) => item.value === size).amount;
      const multiplier = duration === "ANNUAL" ? 12 * 0.8 : 1;

      setCurrentCost(Math.ceil(cost * multiplier));
    }
  };

  const handleSetStorageSize = (_, size) => {
    if (size) {
      const cost = sizeMap.find((item) => item.value === size).amount;
      const multiplier = term === "ANNUAL" ? 12 * 0.8 : 1;

      setCurrentCost(Math.ceil(cost * multiplier));
      setSize(size);
    }
  };

  const sizeMap = [
    {
      name: "3 x 3 x 10",
      value: "SMALL",
      image: SmallCubeIllustration,
      amount: 99.99,
      amountDiscount: 99.99 * 0.8,
      description: "About the size of a closet",
    },
    {
      name: "6 x 6 x 10",
      value: "MEDIUM",
      image: MediumCubeIllustration,
      amount: 179.99,
      amountDiscount: 179.99 * 0.8,
      description: "About the size of half bathroom",
    },
    {
      name: "10 x 10 x 10",
      value: "LARGE",
      image: LargeCubeIllustration,
      amount: 269.99,
      amountDiscount: 269.99 * 0.8,
      description: "About the size of a bedroom",
    },
  ];

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        position="relative"
        height="100%"
        sx={{
          maxWidth: { xs: "100%", sm: "800px" },
          width: "100%",
          margin: { xs: "auto", sm: "2em auto" },
          height: { xs: "100%", sm: "calc(100% - 4em)" },
        }}
      >
        <ScrollingContainer>
          <Card>
            <Box p="2em">
              <Typography variant="h6" component="h2">
                Create Long Term Storage
              </Typography>
              <Typography
                sx={{
                  mt: 2,
                  mb: "0.75em",
                  fontSize: "14px",
                  color: "text.secondary",
                }}
              >
                Create your personal storage space with us. When we create the
                space for you, you are able to add and remove your items from
                your storage anytime.
              </Typography>

              <ToggleButtonGroup
                fullWidth
                color="primary"
                value={term}
                exclusive
                onChange={handleDurationChange}
                aria-label="Select term"
                sx={{ mb: "1em" }}
              >
                <ToggleButton value="MONTHLY">Monthly</ToggleButton>
                <ToggleButton value="ANNUAL">Annual (20% off)</ToggleButton>
              </ToggleButtonGroup>

              <div>
                <ToggleButtonGroup
                  orientation={!smallScreen ? "vertical" : "horizontal"}
                  fullWidth
                  color="primary"
                  value={size}
                  exclusive
                  onChange={handleSetStorageSize}
                  aria-label="Select storage size"
                >
                  {sizeMap.map((size) => (
                    <ToggleButton value={size.value} key={size}>
                      <Box>
                        <img src={size.image} style={{ width: "100px" }} />

                        <div>
                          {term === "ANNUAL" ? (
                            <s style={{ color: "#f99" }}>{size.amount}</s>
                          ) : (
                            size.amount
                          )}
                          {term === "ANNUAL" && (
                            <span style={{ marginLeft: "0.5em" }}>
                              {formatter.format(size.amountDiscount)}
                            </span>
                          )}
                          <span
                            style={{
                              textTransform: "lowercase",
                              fontSize: "12px",
                            }}
                          >
                            &nbsp;/ mo
                          </span>
                        </div>

                        <div>{size.name}</div>
                        <Typography
                          sx={{
                            fontSize: 12,
                            textTransform: "none",
                          }}
                        >
                          {size.description}
                        </Typography>
                      </Box>
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </div>
            </Box>
            <Box
              p="2em"
              pt="0"
              display="flex"
              alignItems="right"
              justifyContent="right"
            >
              <Button variant="outlined" onClick={onClose}>
                Cancel
              </Button>
              <Box ml="1em">
                <Payment
                  amount={currentCost * 100}
                  onSuccessfulPayment={handleCreateStorage}
                />
              </Box>
            </Box>
          </Card>
        </ScrollingContainer>
      </Box>
    </Modal>
  );
}
