import * as React from "react";
import { Box } from "@mui/material";

export function AbsoluteCenterNoResponsive({ children, sx }) {
  return (
    <Box
      position="absolute"
      top="50%"
      sx={{
        ...sx,
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      {children}
    </Box>
  );
}

export default function AbsoluteCenter({ children, sx }) {
  return (
    <Box
      position="absolute"
      top="50%"
      sx={{
        ...sx,
        marginTop: { xs: "2em", sm: "" },
        top: { xs: "", sm: "50%" },
        left: { xs: "", sm: "50%" },
        transform: { xs: "", sm: "translate(-50%, -50%)" },
      }}
    >
      {children}
    </Box>
  );
}
