import PageWrapper from "../PageWrapper/PageWrapper";
import MovesAndDeliveriesPage from "./MovesAndDeliveriesPage";

export default function Component() {
  return (
    <PageWrapper>
      <MovesAndDeliveriesPage />
    </PageWrapper>
  );
}
