import * as React from "react";
import { Box, Button, SwipeableDrawer } from "@mui/material";
import SynclisLogo from "../../../../assets/images/logo.svg";

import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AuthController } from "../../../../service/SynclisService";
import LocalStorageUtility from "../../../../utilities/LocalStorageUtility";
import { useNavigate } from "react-router-dom";
import UserMenu from "../../Header/UserMenu";
import MenuIcon from "@mui/icons-material/Menu";

export default function AdminHeader() {
  const location = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleLogout = () => {
    LocalStorageUtility.delete("synclis_session");
    AuthController.signout().catch(() => {});
    navigate("/login");
  };

  const handleOpenDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  useEffect(() => {
    const queryUser = async () => {
      const storage = await AuthController.authThroughSession().catch((e) => {
        if (e.status) {
          handleLogout();
        }
      });
      setUser(storage);
    };

    queryUser();
  }, []);

  const links = [
    { name: "Users", route: "/admin/users" },
    { name: "Roles", route: "/admin/roles" },
    { name: "Facilties", route: "/admin/facilities" },
    { name: "Storage", route: "/admin/storage" },
    { name: "Moves", route: "/admin/moves" },
    { name: "Parcels", route: "/admin/parcels" },
  ];

  return (
    <>
      <SwipeableDrawer
        anchor="left"
        open={openDrawer}
        onClose={handleOpenDrawer}
        onOpen={() => {}}
        sx={{ display: { xs: "initial", md: "none" } }}
      >
        <Box p="2em" width="100%" minWidth="300px" boxSizing="border-box">
          {links.map((link) => (
            <Link key={link.name} to={link.route}>
              <Box
                border={
                  location.pathname.includes(link.route)
                    ? "2px solid #24b772"
                    : "2px solid transparent"
                }
              >
                <Button>{link.name}</Button>
              </Box>
            </Link>
          ))}
        </Box>
      </SwipeableDrawer>
      <Box
        borderBottom="1px solid #eee"
        display="flex"
        justifyContent="space-between"
        padding="0.25em 1em"
        height="3.25em"
        position="fixed"
        top="0"
        left="0"
        right="0"
        width="100%"
        boxSizing="border-box"
      >
        <Box display="flex" alignItems="center">
          <Link to="/">
            <img src={SynclisLogo} style={{ width: 140, marginRight: "1em" }} />
          </Link>
          {links.map((link) => (
            <Link
              key={link.name}
              to={link.route}
              style={{ textDecoration: "none" }}
            >
              <Box
                sx={{ display: { xs: "none", md: "flex" } }}
                borderBottom={
                  location.pathname.includes(link.route)
                    ? "2px solid #24b772"
                    : "2px solid transparent"
                }
              >
                <Button>{link.name}</Button>
              </Box>
            </Link>
          ))}
        </Box>

        <Box display="flex">
          <Button
            onClick={handleOpenDrawer}
            sx={{ display: { xs: "flex", md: "none" } }}
          >
            <MenuIcon />
          </Button>

          <UserMenu user={user} ignoreAdmin />
        </Box>
      </Box>
    </>
  );
}
