import { Box } from "@mui/material";
import Header from "../Header/Header";

export default function PageWrapper({ children }) {
  return (
    <>
      <Header />
      <Box p="2em" pt="4.5em" mb={{ xs: "3em", md: "0" }}>
        {children}
      </Box>
    </>
  );
}
