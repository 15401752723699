import { Box } from "@mui/material";
import Header from "../HomeHeader/HomeHeader";
import { useEffect, useState } from "react";
import { AuthController } from "../../../../service/SynclisService";
import { useNavigate } from "react-router-dom";
import LocalStorageUtility from "../../../../utilities/LocalStorageUtility";

export default function PageWrapper({ children }) {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const queryUser = async () => {
      if (LocalStorageUtility.get("synclis_session")) {
        const user = await AuthController.authThroughSession().catch(() => {
          setUser({});
        });
        if (user) setUser(user);
      } else {
        setUser({});
      }
    };

    queryUser();
  }, []);

  useEffect(() => {
    if (user?.sessionToken) {
      navigate("/inventory");
    }
  }, [user]);

  if (!user) {
    return null;
  }

  return (
    <Box>
      <Header />
      {children}
    </Box>
  );
}
